
.jo-portfolio .jo-portfolio-content {
  margin: auto;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  padding-top: 90px;
}
.jo-portfolio-list-grid {
  position: relative;
  display: grid;
  grid-template-columns: 49% 2% 49%;
  grid-template-rows: 19.2168% 1.229% 19.2168% 1.229% 19.2168% 1.229% 19.2168% 1.229% 19.2168%;
  width: 90%;
  margin: 20px auto 150px;
  height: auto;
  max-width: 1100px;
  z-index: 10;
}
.jo-portfolio-list-grid > svg {
  grid-area: 1 / 1 / 10 / 4;
 }
.normal-portfolio-block {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  background: #ccc;
  align-items: center;
}
.normal-portfolio-block .jo-portfolio-item {
  width: 100%;
  height: 0px;
}
.normal-portfolio-block .normal-portfolio-content-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-position: top center;
}
.normal-portfolio-block-1 {
  grid-column:1;
  grid-row: 1;
}
.normal-portfolio-block-2 {
  grid-column: 3/4;
  grid-row: 1/4;
}
.normal-portfolio-block-3 {
  grid-column: 1;
  grid-row: 3/6;
}
.normal-portfolio-block-4 {
  grid-column: 3/4;
  grid-row: 5/6;
}
.normal-portfolio-block-5 {
  grid-column: 1;
  grid-row: 7/8; 
}
.normal-portfolio-block-6 {
  grid-column: 3/4;
  grid-row: 7/10;
}
.normal-portfolio-block-7 {
  grid-column: 1;
  grid-row: 9/10
  
}
@media screen and (min-width: 751px) and (max-width: 1000px) {
  .jo-portfolio .jo-portfolio-content {
    padding-top: 70px;
  }
}
@media screen and (max-width: 750px) {
  .jo-portfolio .jo-portfolio-content {
    padding-top: 40px;
  }
  .jo-portfolio-list-grid {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 40px auto;
  }
  .jo-portfolio-list-grid svg {
    display: none;
  }
  .normal-portfolio-block {
    width: 100%;
    display: block;
    height: auto;
    min-height: 200px;
    margin-bottom: 20px;
  }
  .normal-portfolio-block-1 .jo-portfolio-item, .normal-portfolio-block-4 .jo-portfolio-item, .normal-portfolio-block-5 .jo-portfolio-item, .normal-portfolio-block-7 .jo-portfolio-item {
    padding-bottom: 66.67%;
  }
  .normal-portfolio-block-2 .jo-portfolio-item, .normal-portfolio-block-3 .jo-portfolio-item, .normal-portfolio-block-6 .jo-portfolio-item {
    padding-bottom: 133.33%;
  }

}

