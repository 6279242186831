.home-section-one {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  box-sizing: border-box;
  height: auto;
  height: 100vh;
  z-index: 10;
}
.jo-home-shapes-wrapper {
  position: absolute;
  box-sizing: border-box;
  height: 100%;
  height: 100vw;
  width: 100%;
  width: 100vw;
  overflow:hidden;
  right: 0;
  bottom: 0;
}
.jo-home-shape {
  position: absolute;
  height:auto;
}
.jo-home-shape-1 {
  right: 80px;
  bottom: 80px;
  width: 28%;
}
.jo-home-shape-2 {
  right: 25%; /* fallback if needed */
  right: calc(21% + 80px);
  bottom: 14%; /* fallback if needed */
  bottom: calc(10% + 80px);
  width: 27.5%;
}
.jo-home-shape-3 {
  right: 7%; /* fallback if needed */
  right: calc(3% + 80px);
  bottom: 27%; /* fallback if needed */
  bottom: calc(23% + 80px);
  width: 27%;
}
.jo-home-shape-4 {
  right: 28%; /* fallback if needed */
  right: calc(24% + 80px);
  bottom: 44%; /* fallback if needed */
  bottom: calc(40% + 80px);
  width: 23%;
  transform: rotate(-34deg);
}
.jo-home-shape path, .jo-home-shape polygon, .jo-home-shape rect {
  fill: #191919;
}
.jo-home-shape-2 path {
  /* fill: url('#jo-home-landing-shape-gradient') #191919;  */
}
.jo-home-title-wrapper {
  height: auto;
  padding: 30px;
  box-sizing: border-box;
  z-index: 20;
}
h1.jo-home-title {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  font-size: 120px;
}
.jo-home-description {
  text-align: left;
  font-size: 44px;
  font-weight: 300;
}
.jo-scroll-down {
  height: 60px;
  width: 100%;
}

/* Media Queries */
@media screen and ( min-width: 1600px ) {
  h1.jo-home-title {
    font-size: 160px;
  }
  .jo-home-description {
    font-size: 60px;
  }
}
@media screen and ( min-width: 1201px ) {
  .jo-home-title-wrapper {
    padding-left: 80px;
    padding-bottom: 80px;
  }
}
@media screen and ( max-width: 1200px ) {
}
@media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
  .jo-home-title-wrapper {
    padding-left: 40px;
    padding-bottom: 40px;
  }
  h1.jo-home-title {
    font-size: 105px; 
  }
  .jo-home-description {
    font-size: 34px;
  }
  .jo-home-shape-1 {
    right: 40px;
    bottom: 40px;
  }
  .jo-home-shape-2 {
    right: calc(21% + 40px);
    bottom: calc(10% + 40px);
  }
  .jo-home-shape-3 {
    right: calc(3% + 40px);
    bottom: calc(23% + 40px);
  }
  .jo-home-shape-4 {
    right: calc(24% + 40px);
    bottom: calc(40% + 40px);
    width: 21%;
  }
  
}
@media screen and ( min-width: 801px ) and ( max-width: 1000px ) {
  .jo-home-shapes-wrapper {
    bottom: 82px;
  } 
}
@media screen and ( max-width: 800px ) { 
  
}
@media screen and (min-width: 601px) and (max-width: 800px) and (max-height: 780px) {
  .jo-home-shapes-wrapper {
    /* width: 150vw;
    height: 150vw; */
    /* bottom: 110px; */
    bottom: 0px;
  }
}
@media screen and ( min-width: 601px ) and ( max-width: 800px ) {
  h1.jo-home-title {
    font-size: 90px; 
  }
  .jo-home-description {
    font-size: 24px;
  }
  .jo-home-shapes-wrapper {
    width: 130vw;
    height: 130vw;
    bottom: 190px;
    right: 40px;
  }
  .jo-home-shape-1 {
    right: 20px;
    bottom: 20px;
  }
  .jo-home-shape-2 {
    right: calc(21% + 20px);
    bottom: calc(10% + 20px);
  }
  .jo-home-shape-3 {
    right: calc(3% + 20px);
    bottom: calc(23% + 20px);
  }
  .jo-home-shape-4 {
    right: calc(24% + 20px);
    bottom: calc(40% + 20px);
    width: 21%;
  }
}
@media screen and (max-width: 601px) and (max-height: 500px) {
  
}
@media screen and (max-width: 601px) and (max-height: 740px) {
  
}
@media screen and (max-width: 600px) {
  .jo-home-title-wrapper {
    padding-bottom: 80px;
  }
  .jo-home-shapes-wrapper {
    width: 165vw;
    height: 165vw;
    bottom: 130px;
    right: 10px;
  }
  .jo-home-shape-1 {
    right: 20px;
    bottom: 20px;
  }
  .jo-home-shape-2 {
    right: calc(21% + 20px);
    bottom: calc(10% + 20px);
  }
  .jo-home-shape-3 {
    right: calc(3% + 20px);
    bottom: calc(23% + 20px);
  }
  .jo-home-shape-4 {
    right: calc(24% + 20px);
    bottom: calc(40% + 20px);
    width: 23%;
  }
}
@media screen and (min-width: 401px) and ( max-width: 600px ) {
  h1.jo-home-title {
    font-size: 60px; 
  }
  .jo-home-description {
    font-size: 20px;
  }
  .jo-home-shapes-wrapper {
    bottom: 180px;
  }
}

@media screen and ( max-width: 400px ) {
  h1.jo-home-title {
    font-size: 40px; 
  }
  .jo-home-description {
    font-size: 16px;
  }
  .jo-home-shapes-wrapper {
    
    bottom: 150px;
    
  }
}


