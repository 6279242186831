.jo-link-button:hover {
  cursor: pointer;
}
.jo-link-button button, .jo-link-button.inactive:hover button {
  display: block;
  padding: 16px 20px;
  height: 54px;
  transition: padding-right .3s;
  -webkit-transition: padding-right .3s;
}
.jo-link-button:hover button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.jo-link-button-wrapper a {
  text-decoration: none !important;
}
.button-text {
  height: 16px;
  display: flex; 
}
.button-text svg {
  width: 36px;
  height: 36px;
  margin-top: -10px;
}
/* .jo-link-button.jo-link-button-has-icon button:hover {
  background-color: #191919 !important;
  transition: background-color .3s;
  -webkit-transition: background-color .3s;
}
.jo-link-button.jo-link-button-has-icon button:hover span.button-text {
  color: white;
  transition: color .3s;
  -webkit-transition: color .3s;
}
.jo-link-button-has-icon button:hover .button-text svg {
  fill: white;
  transition: fill .3s;
  -webkit-transition: fill .3s;
} */
.jo-link-button button .button-arrow {
  position: relative;
  overflow: hidden;
  opacity: 0;
  transition: width .3s, opacity .3s;
  -webkit-transition: width .3s, opacity .3s;
  height: 16px;
  width: 0px;
}
.jo-link-button.inactive:hover button .button-arrow {
  width: 0px;
}
.jo-link-button:hover button .button-arrow {
  width: 29px;
  opacity: 1;
  animation: blinker 2s linear infinite;
  -webkit-animation: blinker 2s linear infinite;
}
.jo-link-button.large:hover button .button-arrow {
  width: 29px !important;
  opacity: 1;
  animation: blinker 2s linear infinite;
  -webkit-animation: blinker 2s linear infinite;
}
.jo-link-button button .button-arrow svg {
  position: absolute;
  width: 100%;
  height: auto;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  max-height: 20px;
  height: auto;
}
/* Inactive */
.jo-link-button.inactive {
  opacity: .1;
}

/* Large Styles */
.jo-link-button.large {
  width: auto;
  display: flex;
  justify-content: flex-start;
}
.jo-link-button.large button, .jo-link-button.large.inactive button {
  height: 68px;
  padding: 24px;
  padding-top: 20px; 
}
.jo-link-button.large button span.button-text {
  height: 20px;
  font-size: 20px;
}
.jo-link-button.large:hover button .button-arrow {
  height: 20px;
  margin-top: 3px;
  margin-left: 8px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/* Media Queries */
@media screen and (max-width: 1000px) {
  .jo-link-button.small-screen-responsive button {
    padding: 8px 12px;
    height: 38px;
  }
  .jo-link-button.small-screen-responsive button .button-arrow svg {
    max-height: 16px;
  }
  .jo-link-button.small-screen-responsive button  .button-text {
    padding-top: 3px;
    font-size: 10px;
  }
}