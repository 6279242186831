.large-loader-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: auto;
  margin: auto;
}
.absolute-loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.large-loader-wrapper .jo-loading-shape svg {
  width: 100%;
  max-width: 400px;
} 
.jo-loading-shape {
  width: 100%;
  height: auto;
  margin: auto;
  display: flex;
  align-items:center;
  justify-content: center;
}
.jo-loading-shape svg {
  width: 50%;
  height: auto;
  max-width: 250px;
}
.jo-loading-shape svg path {  
  -webkit-animation: loading-shape-rainbow 10s ease-in-out infinite;
  -ms-animation: loading-shape-rainbow 10s ease-in-out infinite;
  -o-animation: loading-shape-rainbow 10s ease-in-out infinite;
  animation: loading-shape-rainbow 10s ease-in-out infinite;
  animation-timing-function: ease-in-out; 
}

/* Rainbow Hover */
@-webkit-keyframes loading-shape-rainbow {
  0% {fill:#fff;}
  10% {fill:#191919;}
  20% {fill: #fff}
  30% {fill:rgb(239, 96, 133);}
  40% {fill: #fff;}
  50% {fill:rgb(26, 167, 167);}
  60% {fill: #fff;}
  70% {fill:rgb(255, 168, 68);}
  80% {fill: #fff;}
  90% {fill:rgb(255, 98, 132);}
  100% {fill:#fff;}
}
@-ms-keyframes loading-shape-rainbow {
  0% {fill:#fff;}
  10% {fill:#191919;}
  20% {fill: #fff}
  30% {fill:rgb(239, 96, 133);}
  40% {fill: #fff;}
  50% {fill:rgb(26, 167, 167);}
  60% {fill: #fff;}
  70% {fill:rgb(255, 168, 68);}
  80% {fill: #fff;}
  90% {fill:rgb(255, 98, 132);}
  100% {fill:#fff;}
}
@-o-keyframes loading-shape-rainbow {
  0% {fill:#fff;}
  10% {fill:#191919;}
  20% {fill: #fff}
  30% {fill:rgb(239, 96, 133);}
  40% {fill: #fff;}
  50% {fill:rgb(26, 167, 167);}
  60% {fill: #fff;}
  70% {fill:rgb(255, 168, 68);}
  80% {fill: #fff;}
  90% {fill:rgb(255, 98, 132);}
  100% {fill:#fff;}
}
@keyframes loading-shape-rainbow {
  0% {fill:#fff;}
  10% {fill:#191919;}
  20% {fill: #fff}
  30% {fill:rgb(239, 96, 133);}
  40% {fill: #fff;}
  50% {fill:rgb(26, 167, 167);}
  60% {fill: #fff;}
  70% {fill:rgb(255, 168, 68);}
  80% {fill: #fff;}
  90% {fill:rgb(255, 98, 132);}
  100% {fill:#fff;}
}
