.jo-social-icons {
  display: flex;
  justify-content: flex-start;
  font-size: 0;
  list-style: none;
  margin-top: 18px;
  text-align: left;
  
}
.jo-social-icons li {
  display: inline-block;
  margin: 2px;
}
.jo-social-icons a, .jo-social-icons svg {
  display: block;
}
.jo-social-icons a {
  position: relative;
  height: 20px;
  height: 2.0rem;
  width: 20px;
  width: 2.0rem;
}
.jo-social-icons svg {
  height: 100%;
  width: 100%;
}
a.facebook {
  position: relative;
  width: 32px;
  height: 32px;  
}
.facebook svg {
  position: absolute;
  left: 4px;
  top: 4px;
  width: 24px;
  height: 24px;
  margin: auto;
}
.jo-social-icons em {
  font-size: 14px;
  line-height: 1.5;
  margin-top: -0.75em;
  position: absolute;
  text-align: center;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
}

.icon-17:hover, .icon-13:hover, .icon-15:hover, .icon-26:hover {
  border-radius: 2px;
  /* color: #0e1a25;
  fill: #0e1a25; */
  -webkit-transform: scale(1.25) rotate(-15deg);
          transform: scale(1.25) rotate(-15deg);
  -webkit-transition: background-color 0.5s, -webkit-transform 0.5s ease-out;
  transition: background-color 0.5s, -webkit-transform 0.5s ease-out;
  transition: background-color 0.5s, transform 0.5s ease-out;
  transition: background-color 0.5s, transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
}
.icon-17 {
  color: #cd5fa1;
  fill: #cd5fa1;
}
.icon-17:hover {
  /* background: #cd5fa1; */
}
.icon-13 {
  color: #f0ba45;
  fill: #f0ba45;
}
.icon-13:hover {
  /* background: #f0ba45; */
}
.icon-15 {
  color: #f89c44;
  fill: #f89c44;
}
.icon-15:hover {
  /* background: #f89c44; */
}
.icon-26 {
  color: #ef6085;
  fill: #ef6085;
}
.icon-26:hover {
  /* background: #ef6085; */
}

