body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* CSS Reset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
  line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* End CSS Reset */
body {
  overflow: visible;
  min-height: 100%;
  min-height: 100vh;
  font-family: 'OpenSans', Helvetica, Arial, sans-serif;
  font-weight: normal;
  background: #fff; /* Old browsers */
}
body p {
  font-weight: 300;
  font-size: 20px;
}
h1, h2 {
  font-family: 'OpenSans', Helvetica, Arial, sans-serif;
}
h1,h2,h3,h4,h5,h6,a {
  font-family: 'OpenSans', Helvetica, Arial, sans-serif;
  font-weight: 500;
}
a {
  text-decoration: none;
  color: #191919;
}
p,h1,h2,h3,h4,h5,h6 {
  color: #191919;
}
textarea, input, button { outline: none; }
button {
	background: none;
  border: none;
  border: 1px solid #191919;
  padding: 14px 18px;
  font-family: 'OpenSans', Helvetica, Arial, sans-serif;
  font-size: .8em;
  text-transform: uppercase;
}
button.blue {
  background: rgb(162, 166, 226);
  color: #fff;
}
button.black {
  background: #191919;
  color: #fff;
}
button:hover {
  -webkit-transition: color .3s ease, background-color .3s ease; /* Safari */
  transition: color .3s ease, background-color .3s ease;
}
button:active {
	position:relative;
}
::-moz-selection {
  background: #f0ba45; /*WebKit/Blink Browsers*/
}
::selection {
  background: #f0ba45; /*WebKit/Blink Browsers*/
}
::-moz-selection {
  background: #f0ba45; /* Gecko Browsers */
}
/* App */
.App {
  position: relative;
  width: 100%;
  height: auto;
  text-align: center;
}
#App {
  position: relative;
  display: -webkit-flex;
  display: flex;
  min-height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}
.main {
  position: relative;
  display: -webkit-flex;
  display: flex;
  min-height: 100vh;
  max-width: 100vw;
  max-width: 100%;
  overflow:hidden;
  -webkit-flex-direction: column;
          flex-direction: column;
}
section {
  position: relative;
}
.flex-section {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
}
.full-height-section {
  height: auto;
  min-height: 100vh;
}
.jo-row {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  clear: both;
}
.jo-content {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 900px;
  margin: auto;
  padding: 20px;
}
.jo-content .snippetcpt-wrap pre {
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
.jo-content ul {
  list-style: disc;
  list-style-position: inside;
}
.jo-section-half {
  width: 50%;
  height: auto;
  max-height: 100%; 
}
.jo-left-align {
  text-align: left;
}
.jo-right-align {
  text-align: right;
}
.icon-17 {
  color: #cd5fa1;
  fill: #cd5fa1;
}
.icon-13 {
  color: #f0ba45;
  fill: #f0ba45;
}
.icon-15 {
  color: #f89c44;
  fill: #f89c44;
}
.icon-26 {
  color: #ef6085;
  fill: #ef6085;
}

/* Blog Styles */
.black-box-text {
  display: inline;
  line-height: 100%;
  padding-left: 0;
  padding-right: 0;
  color: #fff;
  background: #191919;
  box-shadow: 20px 0 0 #191919, -20px 0 0 #191919;
  -webkit-box-decoration-break: clone;
  -o-box-decoration-break: clone;
  box-decoration-break: clone;
}
.black-box-text p {
  display: block;
  line-height: 100%;
  padding: 16px 0px;
  color: #fff;
  background: #191919;
  box-shadow: 20px 0 0 #191919, -20px 0 0 #191919;
  -webkit-box-decoration-break: clone;
  -o-box-decoration-break: clone;
  box-decoration-break: clone;
}
h1.standard-title {
  padding-top: 80px;
  margin: .3em auto;
  font-weight: bold;
  text-transform: uppercase;
}

/* Rainbow Hover */
@-webkit-keyframes white-rainbow {
  0% {color: #cd5fa1;stroke:#cd5fa1;}
  23% {color: #f89c44;stroke:#f89c44;}
  56% {color: #ef6085;stroke:#ef6085;}
  89% {color: #f0ba45;stroke:#f0ba45;}
  100% {color: #cd5fa1;stroke:#cd5fa1;}
}
@keyframes white-rainbow {
  0% {color: #cd5fa1;stroke:#cd5fa1;}
  23% {color: #f89c44;stroke:#f89c44;}
  56% {color: #ef6085;stroke:#ef6085;}
  89% {color: #f0ba45;stroke:#f0ba45;}
  100% {color: #cd5fa1;stroke:#cd5fa1;}
}

/* Background rainbow */
/* Rainbow Hover */
@-webkit-keyframes black-rainbow-background {
  0% {background-color: #cd5fa1;}
  23% {background-color: #f89c44;}
  56% {background-color: #ef6085;}
  89% {background-color: #f0ba45;}
  100% {background-color: #cd5fa1;}
}
@keyframes black-rainbow-background {
  0% {background-color: #cd5fa1;}
  23% {background-color: #f89c44;}
  56% {background-color: #ef6085;}
  89% {background-color: #f0ba45;}
  100% {background-color: #cd5fa1;}
}

/* Media Queries */
@media screen and ( min-width: 1201px ) {
  .jo-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  h1.standard-title {
    font-size: 6em;
  }
}
@media screen and ( max-width: 1200px ) {
}
@media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
  .jo-content {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media screen and (min-width: 601px) and (max-width: 1200px) {
  h1.standard-title {
    font-size: 5em;
  }
}
@media screen and ( min-width: 801px ) {
  h1 {
    font-size: 3rem;
    line-height: 1.05;
  }
  h2 {
    font-size: 2.5rem;
    line-height: 1.25;
  }
  h3 {
    font-size: 1.75rem;
    line-height: 1.25;
  }
  h4 {
    line-height: 1.22222222;
  } 
}
@media screen and ( max-width: 800px ) {
  section {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    min-height: 100vh;
    height: auto;
  }
  .full-height-section {
    height: auto;
    min-height: auto;
  }
}
@media screen and ( min-width: 601px ) and ( max-width: 800px ) {
  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 2.7rem;
    line-height: 1.125;
  }
  h2 {
    font-size: 2.4rem;
    line-height: 1.25;
  }
  h3 {
    font-size: 1.8rem;
    line-height: 1.25;
  }
  h4 {
    line-height: 1.22222222;
  }
  .jo-content {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and ( max-width: 600px ) {
  h1 {
    font-size: 2.5rem;
  }
  h1.standard-title {
    font-size: 2.8em;
  }
  h2 {
    font-size: 2rem;
    line-height: 1.25;
  }
  h3 {
    font-size: 1.5rem;
    line-height: 1.25;
  }
  h4 {
    line-height: 1.22222222;
  }
}



/* Custom Fonts */
@font-face {
  font-family: 'OpenSans';
  src: url(/static/media/OpenSans-Bold.0a191f83.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans';
  src: url(/static/media/OpenSans-Regular.931aebd3.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans';
  src: url(/static/media/OpenSans-Light.c87e3b21.ttf) format('truetype');
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans';
  src: url(/static/media/OpenSans-SemiBold.e2ca235b.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}
/* @font-face {
  font-family: 'Geomanist';
  font-weight: 700;
  font-style: normal;
  src: url('public/fonts/geomanist/geomanist-black-webfont.eot'); 
  src: url('public/fonts/geomanist/geomanist-black-webfont.eot?#iefix') format('embedded-opentype'), 
       url('public/fonts/geomanist/geomanist-black-webfont.woff') format('woff2'), 
       url('public/fonts/geomanist/geomanist-black-webfont.woff2') format('woff'), 
       url('public/fonts/geomanist/geomanist-black-webfont.ttf')  format('truetype'), 
       url('public/fonts/geomanist/geomanist-black-webfont.svg') format('svg'); 
} */
@font-face {
  font-family: 'Geomanist';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/geomanist-bold-webfont.155952a9.eot); /* IE9 Compat Modes */
  src: url(/static/media/geomanist-bold-webfont.155952a9.eot?#iefix) format('embedded-opentype'), 
       url(/static/media/geomanist-bold-webfont.8f7a1442.woff) format('woff2'), 
       url(/static/media/geomanist-bold-webfont.071b4ab4.woff2) format('woff'), 
       url(/static/media/geomanist-bold-webfont.0a774237.ttf)  format('truetype'), 
       url(/static/media/geomanist-bold-webfont.ae26d3ff.svg) format('svg'); /* Legacy iOS */
}

/* React Transitions */
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 600ms ease-in;
  transition: opacity 600ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  -webkit-transition: opacity 600ms ease-in;
  transition: opacity 600ms ease-in;
}
.loading-enter {
  opacity: 0;
}
.loading-enter-active {
  opacity: 1;
  -webkit-transition: opacity 5000ms ease-in;
  transition: opacity 5000ms ease-in;
}
.loading-exit {
  opacity: 1;
}
.loading-exit-active {
  opacity: 0;
  -webkit-transition: opacity 5000ms ease-in;
  transition: opacity 5000ms ease-in;
}


.latest-posts .jo-content {
  display: block;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.jo-blog-loading {
  font-size: 5em;
  -webkit-animation: white-rainbow 6s infinite;
  animation: white-rainbow 6s infinite;
}
.jo-more-posts-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 3em 0 6em;
}
.jo-more-posts-wrapper .jo-loading-shape {
  
}
@media screen and (max-width: 600px) {
  .jo-blog-loading {
    font-size: 2.5em;
  }
}
.jo-link-button:hover {
  cursor: pointer;
}
.jo-link-button button, .jo-link-button.inactive:hover button {
  display: block;
  padding: 16px 20px;
  height: 54px;
  transition: padding-right .3s;
  -webkit-transition: padding-right .3s;
}
.jo-link-button:hover button {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.jo-link-button-wrapper a {
  text-decoration: none !important;
}
.button-text {
  height: 16px;
  display: -webkit-flex;
  display: flex; 
}
.button-text svg {
  width: 36px;
  height: 36px;
  margin-top: -10px;
}
/* .jo-link-button.jo-link-button-has-icon button:hover {
  background-color: #191919 !important;
  transition: background-color .3s;
  -webkit-transition: background-color .3s;
}
.jo-link-button.jo-link-button-has-icon button:hover span.button-text {
  color: white;
  transition: color .3s;
  -webkit-transition: color .3s;
}
.jo-link-button-has-icon button:hover .button-text svg {
  fill: white;
  transition: fill .3s;
  -webkit-transition: fill .3s;
} */
.jo-link-button button .button-arrow {
  position: relative;
  overflow: hidden;
  opacity: 0;
  transition: width .3s, opacity .3s;
  -webkit-transition: width .3s, opacity .3s;
  height: 16px;
  width: 0px;
}
.jo-link-button.inactive:hover button .button-arrow {
  width: 0px;
}
.jo-link-button:hover button .button-arrow {
  width: 29px;
  opacity: 1;
  animation: blinker 2s linear infinite;
  -webkit-animation: blinker 2s linear infinite;
}
.jo-link-button.large:hover button .button-arrow {
  width: 29px !important;
  opacity: 1;
  animation: blinker 2s linear infinite;
  -webkit-animation: blinker 2s linear infinite;
}
.jo-link-button button .button-arrow svg {
  position: absolute;
  width: 100%;
  height: auto;
  left: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  max-height: 20px;
  height: auto;
}
/* Inactive */
.jo-link-button.inactive {
  opacity: .1;
}

/* Large Styles */
.jo-link-button.large {
  width: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.jo-link-button.large button, .jo-link-button.large.inactive button {
  height: 68px;
  padding: 24px;
  padding-top: 20px; 
}
.jo-link-button.large button span.button-text {
  height: 20px;
  font-size: 20px;
}
.jo-link-button.large:hover button .button-arrow {
  height: 20px;
  margin-top: 3px;
  margin-left: 8px;
}

@-webkit-keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/* Media Queries */
@media screen and (max-width: 1000px) {
  .jo-link-button.small-screen-responsive button {
    padding: 8px 12px;
    height: 38px;
  }
  .jo-link-button.small-screen-responsive button .button-arrow svg {
    max-height: 16px;
  }
  .jo-link-button.small-screen-responsive button  .button-text {
    padding-top: 3px;
    font-size: 10px;
  }
}

.jo-list-post {
  display: -webkit-flex;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  margin: 60px auto;
  background-size: cover;
  background-position: center;
  box-shadow: -.5rem .5rem 3.6rem 0.6rem rgba(0,0,0,.12);
}
.jo-list-post .jo-list-post-sizer {
  width: 100%;
  height: 0px;
  padding-bottom: 66.66%;
  opacity:0;
  z-index: -1;
  -webkit-align-self: flex-end;
          align-self: flex-end;
}
.jo-list-post .jo-list-post-featured-img {
  width: 100%;
  height: auto;
  position: absolute;
}
.jo-list-post .jo-list-post-content {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 24px;
  text-align: left;
  top: 0;
  left: 0;
  padding-right: 10px;
  box-sizing: border-box;
  min-height: 100%;
  height: auto;
}
.jo-list-post .jo-list-post-meta {
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  overflow: visible;
}
.jo-list-post .jo-list-post-title {
  -webkit-align-self: flex-start;
          align-self: flex-start;
}
.jo-list-post .jo-list-post-excerpt-wrapper {
  -webkit-align-self: center;
          align-self: center;
  margin-top: 40px;
}
.jo-list-post .jo-list-post-content-spacer {
  margin-top: 64px;
}
.jo-list-post .jo-list-post-read-more {
  position: absolute;
  bottom: -10px;
  right: -16px;
  width: auto;
  z-index: 30;
}
.jo-list-post .jo-link-button-wrapper{
  position: relative;
  left:0;
}
.jo-list-post h1 {
  font-size: 2.5rem;
  text-transform: uppercase; 
}
.jo-list-post .jo-list-post-excerpt p {
  line-height: 140%;
  font-size: 1.3em;
}
.jo-list-post .jo-list-post-date {
  position: absolute;
  right: 0px;
  top: 28px;
}
.jo-list-post .jo-list-post-date .black-box-text {
  padding: 8px 0px;
  box-shadow: 12px 0 0 #191919, -12px 0 0 #191919;
}

@media screen and (min-width: 601px) and ( max-width: 800px ) { 
  .jo-list-post h1 {
    font-size: 2.1rem;
  }
  .jo-list-post .jo-list-post-excerpt p {
    font-size: 1.1em;
  }
  
}
@media screen and ( max-width: 800px ) { 
 
  .jo-list-post {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .jo-list-post .jo-list-post-featured-img, .jo-list-post-content, .jo-list-post .jo-list-post-meta {
    position: relative;
  }
  .jo-list-post .jo-list-post-title {
   z-index: 40; 
  }
  .jo-list-post .jo-list-post-content {
    padding: 0;
    margin-top: -100px;
    width: 100%; 
  }
  .jo-list-post .jo-list-post-excerpt-wrapper {
    margin-top: 0px;
    background: #fff;
    padding: 10px 20px;
    box-sizing: border-box;
    width: 100%;
  }
  .jo-list-post .jo-list-post-excerpt-wrapper .black-box-text p {
    padding: 20px 10px 20px;
    box-sizing: border-box;
    background: none;
    color: #191919;
    box-shadow: none;
  }
  .jo-list-post .jo-list-post-date {
    display: none;
  }
  .jo-list-post .jo-list-post-meta {
    width: 100%;
    height: 0px;
    right: -20px;
    top: -30px;
  }
  .jo-list-post .jo-list-post-read-more {
    position: relative;
    right: 0;
    bottom: 0;
    text-align: right;
  }
  .jo-list-post .jo-link-button-wrapper {
    right: 0;
    display: inline-block;
  }
  .jo-list-post .jo-list-post-sizer, .jo-list-post .jo-list-post-content-spacer {
    display: none;
  }
  
}
@media screen and (max-width: 600px) {
  .jo-list-post h1 {
    font-size: 2rem;
  }
  .jo-list-post .jo-list-post-excerpt p {
    font-size: 1em;
  }
}
.large-loader-wrapper {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 400px;
  height: auto;
  margin: auto;
}
.absolute-loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.large-loader-wrapper .jo-loading-shape svg {
  width: 100%;
  max-width: 400px;
} 
.jo-loading-shape {
  width: 100%;
  height: auto;
  margin: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items:center;
          align-items:center;
  -webkit-justify-content: center;
          justify-content: center;
}
.jo-loading-shape svg {
  width: 50%;
  height: auto;
  max-width: 250px;
}
.jo-loading-shape svg path {  
  -webkit-animation: loading-shape-rainbow 10s ease-in-out infinite;
  animation: loading-shape-rainbow 10s ease-in-out infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out; 
}

/* Rainbow Hover */
@-webkit-keyframes loading-shape-rainbow {
  0% {fill:#fff;}
  10% {fill:#191919;}
  20% {fill: #fff}
  30% {fill:rgb(239, 96, 133);}
  40% {fill: #fff;}
  50% {fill:rgb(26, 167, 167);}
  60% {fill: #fff;}
  70% {fill:rgb(255, 168, 68);}
  80% {fill: #fff;}
  90% {fill:rgb(255, 98, 132);}
  100% {fill:#fff;}
}
@keyframes loading-shape-rainbow {
  0% {fill:#fff;}
  10% {fill:#191919;}
  20% {fill: #fff}
  30% {fill:rgb(239, 96, 133);}
  40% {fill: #fff;}
  50% {fill:rgb(26, 167, 167);}
  60% {fill: #fff;}
  70% {fill:rgb(255, 168, 68);}
  80% {fill: #fff;}
  90% {fill:rgb(255, 98, 132);}
  100% {fill:#fff;}
}

@-webkit-keyframes float1 {
	0% {
		-webkit-transform: translateY(0px);
		        transform: translateY(0px);
	}
	50% {
		-webkit-transform: translateY(4px);
		        transform: translateY(4px);
	}
	100% {
		-webkit-transform: translateY(0px);
		        transform: translateY(0px);
	}
}
@keyframes float1 {
	0% {
		-webkit-transform: translateY(0px);
		        transform: translateY(0px);
	}
	50% {
		-webkit-transform: translateY(4px);
		        transform: translateY(4px);
	}
	100% {
		-webkit-transform: translateY(0px);
		        transform: translateY(0px);
	}
}
@-webkit-keyframes float2 {
	0% {
		-webkit-transform: translateY(0px) translateX(0px) rotate(0deg);
		        transform: translateY(0px) translateX(0px) rotate(0deg);
	}
	50% {
		-webkit-transform: translateY(-10px) translateX(2px) rotate(1deg);
		        transform: translateY(-10px) translateX(2px) rotate(1deg);
	}
	100% {
		-webkit-transform: translateY(0px) translateX(0px) rotate(0deg);
		        transform: translateY(0px) translateX(0px) rotate(0deg);
	}
}
@keyframes float2 {
	0% {
		-webkit-transform: translateY(0px) translateX(0px) rotate(0deg);
		        transform: translateY(0px) translateX(0px) rotate(0deg);
	}
	50% {
		-webkit-transform: translateY(-10px) translateX(2px) rotate(1deg);
		        transform: translateY(-10px) translateX(2px) rotate(1deg);
	}
	100% {
		-webkit-transform: translateY(0px) translateX(0px) rotate(0deg);
		        transform: translateY(0px) translateX(0px) rotate(0deg);
	}
}
@-webkit-keyframes float3 {
	0% {
		-webkit-transform: translateY(0px) rotate(0deg);
		        transform: translateY(0px) rotate(0deg);
	}
	50% {
		-webkit-transform: translateY(-10px) rotate(1deg);
		        transform: translateY(-10px) rotate(1deg);
	}
	100% {
		-webkit-transform: translateY(0px) rotate(0deg);
		        transform: translateY(0px) rotate(0deg);
	}
}
@keyframes float3 {
	0% {
		-webkit-transform: translateY(0px) rotate(0deg);
		        transform: translateY(0px) rotate(0deg);
	}
	50% {
		-webkit-transform: translateY(-10px) rotate(1deg);
		        transform: translateY(-10px) rotate(1deg);
	}
	100% {
		-webkit-transform: translateY(0px) rotate(0deg);
		        transform: translateY(0px) rotate(0deg);
	}
}
@-webkit-keyframes float4 {
	0% {
		-webkit-transform: rotate(-34deg) translateY(0px);
		        transform: rotate(-34deg) translateY(0px);
	}
	50% {
		-webkit-transform: rotate(-42deg) translateY(-8px);
		        transform: rotate(-42deg) translateY(-8px);
	}
	100% {
		-webkit-transform: rotate(-34deg) translateY(0px);
		        transform: rotate(-34deg) translateY(0px);
	}
}
@keyframes float4 {
	0% {
		-webkit-transform: rotate(-34deg) translateY(0px);
		        transform: rotate(-34deg) translateY(0px);
	}
	50% {
		-webkit-transform: rotate(-42deg) translateY(-8px);
		        transform: rotate(-42deg) translateY(-8px);
	}
	100% {
		-webkit-transform: rotate(-34deg) translateY(0px);
		        transform: rotate(-34deg) translateY(0px);
	}
}
.jo-home-shape-4 {
  -webkit-animation: float4 18s ease-in-out infinite;
          animation: float4 18s ease-in-out infinite;
}
.jo-home-shape-3 {
  -webkit-animation: float3 8s ease-in-out infinite;
          animation: float3 8s ease-in-out infinite;
}
.jo-home-shape-2 {
  -webkit-animation: float2 12s ease-in-out infinite;
          animation: float2 12s ease-in-out infinite;
}


.jo-portfolio .jo-portfolio-content {
  margin: auto;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  padding-top: 90px;
}
.jo-portfolio-list-grid {
  position: relative;
  display: grid;
  grid-template-columns: 49% 2% 49%;
  grid-template-rows: 19.2168% 1.229% 19.2168% 1.229% 19.2168% 1.229% 19.2168% 1.229% 19.2168%;
  width: 90%;
  margin: 20px auto 150px;
  height: auto;
  max-width: 1100px;
  z-index: 10;
}
.jo-portfolio-list-grid > svg {
  grid-area: 1 / 1 / 10 / 4;
 }
.normal-portfolio-block {
  display: -webkit-flex;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  background: #ccc;
  -webkit-align-items: center;
          align-items: center;
}
.normal-portfolio-block .jo-portfolio-item {
  width: 100%;
  height: 0px;
}
.normal-portfolio-block .normal-portfolio-content-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-position: top center;
}
.normal-portfolio-block-1 {
  grid-column:1;
  grid-row: 1;
}
.normal-portfolio-block-2 {
  grid-column: 3/4;
  grid-row: 1/4;
}
.normal-portfolio-block-3 {
  grid-column: 1;
  grid-row: 3/6;
}
.normal-portfolio-block-4 {
  grid-column: 3/4;
  grid-row: 5/6;
}
.normal-portfolio-block-5 {
  grid-column: 1;
  grid-row: 7/8; 
}
.normal-portfolio-block-6 {
  grid-column: 3/4;
  grid-row: 7/10;
}
.normal-portfolio-block-7 {
  grid-column: 1;
  grid-row: 9/10
  
}
@media screen and (min-width: 751px) and (max-width: 1000px) {
  .jo-portfolio .jo-portfolio-content {
    padding-top: 70px;
  }
}
@media screen and (max-width: 750px) {
  .jo-portfolio .jo-portfolio-content {
    padding-top: 40px;
  }
  .jo-portfolio-list-grid {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    margin: 40px auto;
  }
  .jo-portfolio-list-grid svg {
    display: none;
  }
  .normal-portfolio-block {
    width: 100%;
    display: block;
    height: auto;
    min-height: 200px;
    margin-bottom: 20px;
  }
  .normal-portfolio-block-1 .jo-portfolio-item, .normal-portfolio-block-4 .jo-portfolio-item, .normal-portfolio-block-5 .jo-portfolio-item, .normal-portfolio-block-7 .jo-portfolio-item {
    padding-bottom: 66.67%;
  }
  .normal-portfolio-block-2 .jo-portfolio-item, .normal-portfolio-block-3 .jo-portfolio-item, .normal-portfolio-block-6 .jo-portfolio-item {
    padding-bottom: 133.33%;
  }

}


.jo-home-portfolio-item-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.home-portfolio-block .jo-portfolio-item {
  position: relative;
  display: inline-block; 
  width: 100%;
  height: 100%;
  padding: 0px; 
}
.normal-portfolio-content-wrapper {
  position:relative;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  box-shadow: .75rem 1.25rem 2.5rem 0.35rem rgba(0,0,0,.08);
  overflow: hidden;
}
.jo-portfolio-item-normal .normal-portfolio-hover-content {
  position: relative;
  visibility: hidden;
  opacity: 0;
  height: 101%;
  width: 101%;
  padding: 20px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  transition: opacity .3s ease-in-out;
  -webkit-transition: opacity .3s ease-in-out;
  background: #ef6085; /* Old browsers */ /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, #ef6085 1%,#f89c44 48%,#f0ba45 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #ef6085 1%,#f89c44 48%,#f0ba45 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ef6085', endColorstr='#f0ba45',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  cursor: pointer;
}
.jo-portfolio-item-normal .normal-portfolio-hover-content a {
  visibility: hidden;
  position: relative;
  -webkit-transition: visibility .5s ease-in-out;
  transition: visibility .5s ease-in-out;
  margin-left: -9999px;
}
.normal-portfolio-hover-content h1, .normal-portfolio-hover-content p {
  color: #fff;
}
.normal-portfolio-hover-content h1 {
  font-size: 3rem;
  margin-bottom: .8rem;
  text-transform: uppercase;
}
.jo-portfolio-item-normal:hover .normal-portfolio-hover-content, .jo-portfolio-item-normal.hovered .normal-portfolio-hover-content {
  visibility: visible;
  opacity: 1;
}
.jo-portfolio-item-normal:hover .normal-portfolio-hover-content a, .jo-portfolio-item-normal.hovered .normal-portfolio-hover-content a{
  visibility: visible;
  margin-left: auto;
  margin-right: auto;
}
.normal-portfolio-hover-content a:before {
  content: "";
  position: absolute;
  width: 90%;
  left: 5%;
  height: 2px;
  bottom: 5px;
  background-color: #fff;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.normal-portfolio-hover-content a.normal-portfolio-title:hover:before, .normal-portfolio-hover-content a.normal-portfolio-title:focus:before, .normal-portfolio-hover-content a.normal-portfolio-title:active:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.normal-portfolio-hover-content p {
  font-size: 1.5rem;
}

@media screen and (max-width: 600px) {
  .normal-portfolio-hover-content h1 {
    font-size: 2rem;
    margin-bottom: .4rem;
  }
  .normal-portfolio-hover-content p {
    font-size: 1rem;
  }
}
@media screen and (min-width: 751px) and (max-width: 1200px) {
  .normal-portfolio-hover-content h1 {
    font-size: 2rem;
    margin-bottom: .4rem;
  }
  .normal-portfolio-hover-content p {
    font-size: 1rem;
  }
}
@media screen and (min-width:1201px) {
  .normal-portfolio-hover-content h1 {
    font-size: 2.5rem;
    margin-bottom: .6rem;
  }
  .normal-portfolio-hover-content p {
    font-size: 1.2rem;
  }
}
.jo-transform-box {
  width: 100%;
  height: 100%;
}
.jo-transform-box-inner {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  margin: 0px;
  padding: 0px;
  -webkit-transition: all .8s cubic-bezier(.42,0,0,1);
  transition: all .8s cubic-bezier(.42,0,0,1);
  will-change: transform;
  box-shadow: .75rem 1.25rem 2.5rem 0.35rem rgba(0,0,0,.08);
  color: #fff;
  cursor: pointer;
}
.jo-transform-box-inner h1, .jo-transform-box-inner h2, .jo-transform-box-inner h3, .jo-transform-box-inner p, .jo-transform-box-inner a {
  color: #fff;
} 

.jo-transform-box-inner-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: #000;
  -webkit-transition: all .8s cubic-bezier(.42,0,0,1);
  transition: all .8s cubic-bezier(.42,0,0,1);
}
.jo-transform-box-inner:hover .jo-transform-box-inner-container {
}
.jo-transform-box-bg-image {
  position: absolute;
  width: 100%;
  height: 100%;
  left:0;
  top:0;
  background-position: center center;
  background-size: cover;
  z-index: 5; 
}
.jo-transform-box-content-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: transparent;
  -webkit-transition: background-color .8s cubic-bezier(.42,0,0,1);
  transition: background-color .8s cubic-bezier(.42,0,0,1);
  z-index: 30;
}
.jo-transform-box-inner:hover .jo-transform-box-content-wrapper {
  background-color: rgba(0,0,0,.7);
}
.jo-transform-box-content {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  opacity: 0;  
  -webkit-transition: all .8s cubic-bezier(.42,0,0,1);  
  transition: all .8s cubic-bezier(.42,0,0,1);
  background-color: none;
  z-index: 40;
}
.jo-transform-box-inner:hover .jo-transform-box-content {
  opacity: 1;
}
.jo-transform-box-content h3.jo-portfolio-item-title, .jo-transform-box-content p.jo-portfolio-item-technologies, .jo-transform-box-content p.jo-portfolio-item-description {
  text-align: center;
}
.jo-transform-box-content h3.jo-portfolio-item-title {
  font-size: 1.2rem;
  font-weight: bold;
}
.jo-transform-box-content p.jo-portfolio-item-technologies {
  font-size: .7rem;
}
.jo-transform-box-content p.jo-portfolio-item-description {
  font-size: 1rem;
  text-transform: none;
}
/* Media Queries */
@media screen and ( min-width: 1201px ) {
  .jo-transform-box-content h3.jo-portfolio-item-title {
    font-size: 1.8rem;
    line-height: 1.25;
    margin-bottom: .5rem;
  }
  .jo-transform-box-content p.jo-portfolio-item-technologies {
    font-size: .7rem;
    margin-bottom: .7rem;
  }
  .jo-transform-box-content p.jo-portfolio-item-description {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1.2rem;
  }
}
@media screen and ( min-width: 1001px ) and ( max-width: 1200px ) {
  .jo-transform-box-content h3.jo-portfolio-item-title {
    font-size: 1.5rem;
    line-height: 1.25;
    margin-bottom: .5rem;
  }
  .jo-transform-box-content p.jo-portfolio-item-technologies {
    font-size: .6rem;
    margin-bottom: .5rem;
  }
  .jo-transform-box-content p.jo-portfolio-item-description {
    font-size: 1rem;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
}
@media screen and ( max-width: 1000px ) {
  .jo-transform-box-content h3.jo-portfolio-item-title {
    font-size: 1.3rem;
    line-height: 1.25;
    margin-bottom: .3rem;
  }
  .jo-transform-box-content p.jo-portfolio-item-technologies {
    font-size: .6rem;
    margin-bottom: .5rem;
  }
  .jo-transform-box-content p.jo-portfolio-item-description {
    font-size: .9rem;
    line-height: 1.2;
    margin-bottom: .7rem;
  }
  .jo-transform-box-content .jo-link-button-wrapper {
    margin-top: .5rem;
  }
}
@media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
  
}
@media screen and (min-width: 601px) and (max-width: 1200px) {
 
}
@media screen and ( min-width: 801px ) {

}
@media screen and ( max-width: 800px ) {
  
}
@media screen and ( min-width: 601px ) and ( max-width: 800px ) {
  
}
@media screen and ( max-width: 600px ) {
  
}
.jo-not-found {
  width: 100%;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.jo-not-found svg {
  width: 30%;
  height: auto;
}
.jo-not-found-text {
  width: 70%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction:column;
          flex-direction:column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
}
.jo-not-found-text h1 {
  text-transform: uppercase;
  margin-bottom: 10px;
}
.jo-not-found-text h3 {
  -webkit-animation: white-rainbow 6s infinite;
  animation: white-rainbow 6s infinite;
}
.jo-not-found-text a {
  text-decoration: none;
}
@media screen and ( min-width: 1201px ) { 
  .jo-not-found-text h1 {
    font-size: 3.8rem;
  }
  .jo-not-found-text h3 {
    font-size: 2.35rem;
  }
}
@media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
  .jo-not-found-text h1{
   font-size: 3.2rem;
  }
  .jo-not-found-text h3 {
    font-size: 1.95rem;
  }
}
@media screen and ( min-width: 601px ) and ( max-width: 800px ) {
  .jo-not-found-text h1 {
    font-size: 2.7rem;
  }
  .jo-not-found-text h3 {
    font-size: 1.6rem;
  }
}
@media screen and ( max-width: 600px ) {
  .jo-not-found-text h1 {
    font-size: 1.8rem;
  }
  .jo-not-found-text h3 {
    font-size: 1.1rem;
  }
}
.single-post {
  min-height: 100vh;
}
article.jo-post {
  display: block;
  position: relative;
  margin: 100px auto 100px;
  text-align: left;
}
article.jo-post img, .jo-post-content div.wp-caption {
  max-width: 100% !important;
}
.jo-post .jo-featured-image {
  z-index: 10;
  position: relative;
}
.jo-post h1.jo-post-title {
  position: relative;
  font-size: 4.5rem;
  text-indent: 200px;
  text-transform: uppercase;
  z-index: 50;
}
.jo-post h1, .jo-post h2, .jo-post h3, .jo-post h4, .jo-post h5, .jo-post h6, .jo-post p {
  text-align: left;
}
.jo-post a {
  text-decoration: none;
  font-weight: 500;
  color: #191919;
}
.jo-post-content-wrapper {
  text-align: left;
}
.jo-post .jo-post-content-wrapper {
  text-align: left;
  margin-top: -10rem;
}
.jo-post-content {
  position: relative;
  padding-top: .5rem;
  background: #fff;
  z-index: 25;
}
.jo-post-content-wrapper video, .jo-post-content-wrapper iframe {
  box-sizing: border-box;
  max-width: 100%;
}
.jo-post-content-wrapper img {
  box-sizing: border-box;
  max-width: 100%;
  height: auto;
  padding: 4px;
}
.jo-post-content-wrapper p {
  line-height: 160%;
}
.jo-post-content-wrapper a {
  color: #191919;
  font-weight: bold;
  text-decoration: none;
}
.jo-post-content-wrapper a:hover {
  
}
.jo-post-content-wrapper img.aligncenter {
  margin: .5rem 0px;
  box-sizing: border-box;
}
.jo-post-content-wrapper .snippetcpt-wrap {
  display: block;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: -2000px;
  padding: 25px 2000px;
  box-sizing: content-box;
  width: 100%;
  overflow-x: visible;
  text-align: left;
  border: 1px solid rgb(8, 8, 8);
  border-radius: 4px;
  background: rgb(34, 34, 34);
  font-family: "Courier New", Courier, monospace;
  line-height: 130%;
  color: rgba(255,255,255,.9);
}
.jo-post-content-wrapper .snippetcpt-wrap pre {
  width: 100%;
}
.jo-post a:hover, .jo-post a:active, .jo-post a:focus, .jo-post-content-wrapper a:hover, .jo-post-content-wrapper a:active, .jo-post-content-wrapper a:focus {
  -webkit-animation: white-rainbow 6s infinite;
  animation: white-rainbow 6s infinite;
}
.jo-post-contact-button {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: auto;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 2em 0;

}

/* Media Queries */
@media screen and ( min-width: 1201px ) { 
}
@media screen and ( max-width: 1200px ) {
  
}
@media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
  .jo-post h1.jo-post-title {
    margin-left: -40px;
  }
}
@media screen and ( min-width: 800px ) {
  .jo-post .jo-post-content-wrapper {
    padding-top: 20px;
  }
  .jo-post-content-wrapper h1 {
    font-size: 3rem;
    line-height: 1.05;
    margin: 2rem auto 1.5rem;
  }
  .jo-post-content-wrapper h2 {
    font-size: 2.5rem;
    line-height: 1.25;
    margin: 1.6rem auto 1.2rem;
  }
  .jo-post-content-wrapper h3 {
    font-size: 1.75rem;
    line-height: 1.25;
    margin: 1rem auto .75rem;
  }
  .jo-post-content-wrapper h4 {
    line-height: 1.22222222;
    margin: .75rem auto .63rem;
  }
  .jo-post-content-wrapper p, .jo-post-content-wrapper ul li {
    font-size: 1.4rem;
    line-height: 160%;
    margin: .9rem auto .7rem;
  }
  .jo-post-content-wrapper ul li {
    line-height: 140%;
    list-style: disc inside;
  }
  .jo-post-content-wrapper .snippetcpt-wrap {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .jo-post-content-wrapper .snippetcpt-wrap pre {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 800px) {
  .jo-post-content-wrapper {
  }
}
@media screen and ( min-width: 601px ) and ( max-width: 800px ) {
  .jo-post .jo-post-content-wrapper {
    margin-top: -4rem;
  }
  .jo-post h1.jo-post-title {
    font-size: 2.5rem;
    margin-left: -20px;
  }
  .jo-post-content-wrapper h1 {
    font-size: 2.7rem;
    /* 2.5x body copy size = 40px */
    line-height: 1.125;
    margin: 1.6rem auto 1.35rem;
  }
  .jo-post-content-wrapper h2 {
    font-size: 2.4rem;
    /* 2x body copy size = 32px */
    line-height: 1.25;
    margin: 1.45rem auto .9rem;
  }
  .jo-post-content-wrapper h3 {
    font-size: 1.8rem;
    /* 1.5x body copy size = 24px */
    line-height: 1.25;
    margin: .9rem auto .75rem;
  }
  .jo-post-content-wrapper h4 {
    line-height: 1.22222222;
    /* (22px / 18px */
    margin: .75rem auto .55rem;
  }
  .jo-post-content-wrapper p, .jo-post-content-wrapper ul li  {
    font-size: 1.2rem;
    margin: .85rem auto .6rem;
    line-height: 155%;
  }
  .jo-post-content-wrapper .snippetcpt-wrap pre {
    font-size: .95rem;
  }
}
@media screen and ( max-width: 600px ) {
  .jo-post .jo-post-content-wrapper {
    margin-top: -6rem;
  }
  .jo-post h1.jo-post-title {
    font-size: 2.5rem;
  }
  .jo-post-content-wrapper h1 {
    font-size: 2.5rem;
    /* 2.5x body copy size = 40px */
    line-height: 1.125;
    margin: 1.4rem auto 1.1rem;
  }
  .jo-post-content-wrapper h2 {
    font-size: 2rem;
    /* 2x body copy size = 32px */
    line-height: 1.25;
    margin: 1.2rem auto .75rem;
  }
  .jo-post-content-wrapper h3 {
    font-size: 1.5rem;
    /* 1.5x body copy size = 24px */
    line-height: 1.25;
    margin: .75rem auto .63rem;
  }
  .jo-post-content-wrapper h4 {
    line-height: 1.22222222;
    /* (22px / 18px */
    margin: .63rem auto .46rem;
  }
  .jo-post-content-wrapper p, .jo-post-content-wrapper ul li {
    font-size: 1rem;
    margin: .7rem auto .5rem;
  }
  .jo-post-content-wrapper .snippetcpt-wrap pre {
    font-size: .8rem;
  }
}
.home-section-one {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  box-sizing: border-box;
  height: auto;
  height: 100vh;
  z-index: 10;
}
.jo-home-shapes-wrapper {
  position: absolute;
  box-sizing: border-box;
  height: 100%;
  height: 100vw;
  width: 100%;
  width: 100vw;
  overflow:hidden;
  right: 0;
  bottom: 0;
}
.jo-home-shape {
  position: absolute;
  height:auto;
}
.jo-home-shape-1 {
  right: 80px;
  bottom: 80px;
  width: 28%;
}
.jo-home-shape-2 {
  right: 25%; /* fallback if needed */
  right: calc(21% + 80px);
  bottom: 14%; /* fallback if needed */
  bottom: calc(10% + 80px);
  width: 27.5%;
}
.jo-home-shape-3 {
  right: 7%; /* fallback if needed */
  right: calc(3% + 80px);
  bottom: 27%; /* fallback if needed */
  bottom: calc(23% + 80px);
  width: 27%;
}
.jo-home-shape-4 {
  right: 28%; /* fallback if needed */
  right: calc(24% + 80px);
  bottom: 44%; /* fallback if needed */
  bottom: calc(40% + 80px);
  width: 23%;
  -webkit-transform: rotate(-34deg);
          transform: rotate(-34deg);
}
.jo-home-shape path, .jo-home-shape polygon, .jo-home-shape rect {
  fill: #191919;
}
.jo-home-shape-2 path {
  /* fill: url('#jo-home-landing-shape-gradient') #191919;  */
}
.jo-home-title-wrapper {
  height: auto;
  padding: 30px;
  box-sizing: border-box;
  z-index: 20;
}
h1.jo-home-title {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  font-size: 120px;
}
.jo-home-description {
  text-align: left;
  font-size: 44px;
  font-weight: 300;
}
.jo-scroll-down {
  height: 60px;
  width: 100%;
}

/* Media Queries */
@media screen and ( min-width: 1600px ) {
  h1.jo-home-title {
    font-size: 160px;
  }
  .jo-home-description {
    font-size: 60px;
  }
}
@media screen and ( min-width: 1201px ) {
  .jo-home-title-wrapper {
    padding-left: 80px;
    padding-bottom: 80px;
  }
}
@media screen and ( max-width: 1200px ) {
}
@media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
  .jo-home-title-wrapper {
    padding-left: 40px;
    padding-bottom: 40px;
  }
  h1.jo-home-title {
    font-size: 105px; 
  }
  .jo-home-description {
    font-size: 34px;
  }
  .jo-home-shape-1 {
    right: 40px;
    bottom: 40px;
  }
  .jo-home-shape-2 {
    right: calc(21% + 40px);
    bottom: calc(10% + 40px);
  }
  .jo-home-shape-3 {
    right: calc(3% + 40px);
    bottom: calc(23% + 40px);
  }
  .jo-home-shape-4 {
    right: calc(24% + 40px);
    bottom: calc(40% + 40px);
    width: 21%;
  }
  
}
@media screen and ( min-width: 801px ) and ( max-width: 1000px ) {
  .jo-home-shapes-wrapper {
    bottom: 82px;
  } 
}
@media screen and ( max-width: 800px ) { 
  
}
@media screen and (min-width: 601px) and (max-width: 800px) and (max-height: 780px) {
  .jo-home-shapes-wrapper {
    /* width: 150vw;
    height: 150vw; */
    /* bottom: 110px; */
    bottom: 0px;
  }
}
@media screen and ( min-width: 601px ) and ( max-width: 800px ) {
  h1.jo-home-title {
    font-size: 90px; 
  }
  .jo-home-description {
    font-size: 24px;
  }
  .jo-home-shapes-wrapper {
    width: 130vw;
    height: 130vw;
    bottom: 190px;
    right: 40px;
  }
  .jo-home-shape-1 {
    right: 20px;
    bottom: 20px;
  }
  .jo-home-shape-2 {
    right: calc(21% + 20px);
    bottom: calc(10% + 20px);
  }
  .jo-home-shape-3 {
    right: calc(3% + 20px);
    bottom: calc(23% + 20px);
  }
  .jo-home-shape-4 {
    right: calc(24% + 20px);
    bottom: calc(40% + 20px);
    width: 21%;
  }
}
@media screen and (max-width: 601px) and (max-height: 500px) {
  
}
@media screen and (max-width: 601px) and (max-height: 740px) {
  
}
@media screen and (max-width: 600px) {
  .jo-home-title-wrapper {
    padding-bottom: 80px;
  }
  .jo-home-shapes-wrapper {
    width: 165vw;
    height: 165vw;
    bottom: 130px;
    right: 10px;
  }
  .jo-home-shape-1 {
    right: 20px;
    bottom: 20px;
  }
  .jo-home-shape-2 {
    right: calc(21% + 20px);
    bottom: calc(10% + 20px);
  }
  .jo-home-shape-3 {
    right: calc(3% + 20px);
    bottom: calc(23% + 20px);
  }
  .jo-home-shape-4 {
    right: calc(24% + 20px);
    bottom: calc(40% + 20px);
    width: 23%;
  }
}
@media screen and (min-width: 401px) and ( max-width: 600px ) {
  h1.jo-home-title {
    font-size: 60px; 
  }
  .jo-home-description {
    font-size: 20px;
  }
  .jo-home-shapes-wrapper {
    bottom: 180px;
  }
}

@media screen and ( max-width: 400px ) {
  h1.jo-home-title {
    font-size: 40px; 
  }
  .jo-home-description {
    font-size: 16px;
  }
  .jo-home-shapes-wrapper {
    
    bottom: 150px;
    
  }
}



#jo-home-boids {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  height: 100vh;
  width: 100%;
  width: 100vw;
  max-height: 100%;
  max-height: 100vh;
  max-width: 100%;
  max-width: 100vw;
  overflow: hidden;
}

.what-we-do-heading {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  box-sizing: border-box;
  margin-bottom: 1em;
  margin-left: 24px;
  width: 100%;
  margin-bottom: 1em;
  margin-left: 24px;
}
.what-we-do-heading h3 {
  width: 80%; 
  text-align: left;
  font-weight:500;
  font-size: 1.8em;
  line-height: 110%;
}
#home-section-two .home-section-content {
  padding-top: 10%;
}
.what-we-do {
  position:relative;
  box-sizing: border-box;
  background: #fff;
}
.home-section-two-bg {
  position: absolute;
  width: 60%;
  background-size: cover;
  background-position: right center;
  height: 70%;
  height: 70vh;
  right: 0px;
  top: 30%;
  top: 30vh;
  background: #ccc;
}

@media screen and (min-width: 1601px) {
  .what-we-do {
    width: 60%;
    max-width: 1200px;
    padding:  102px 100px;
    box-shadow: 1.5rem 2.5rem 5rem 0.7rem rgba(0,0,0,.08);
  }
  .what-we-do-heading {
    margin-bottom: 1.8em;
  }
  .what-we-do-heading h3 {
    font-size: 2.4em;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1600px) {
  .what-we-do {
    max-width: 700px;
    padding:  82px 80px;
    box-shadow: 1.5rem 2.5rem 5rem 0.7rem rgba(0,0,0,.08);
  }
  .what-we-do-heading {
    margin-bottom: 1em;
  }
  .what-we-do-heading h3 {
    font-size: 2em;
  }
}
@media screen and ( min-width: 1201px ) {
  #home-section-two .home-section-content {
    padding-top: 7%;
  }
}
@media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
  .what-we-do {
    max-width: 660px;
    padding:  66px 60px;
    box-shadow: .75rem 1.25rem 2.5rem 0.35rem rgba(0,0,0,.08);
  }
  .what-we-do-heading {
    margin-bottom: 1em;
  }
  .what-we-do-heading h3 {
    font-size: 1.8em;
  }
}
@media screen and (min-width: 801px) {
  
}
@media screen and (max-width: 800px ) {
  .what-we-do {
    padding: 0px;
  }
  .home-section-two-bg {
    display: none;
  }
  
}
@media screen and (min-width: 601px) and (max-width: 800px) {
  
  .what-we-do-heading {
    margin-bottom: 1em;
    margin-left: 0;
  }
  .what-we-do-heading h3 {
    max-width: 60%;
    font-size: 1.4em;
  }
  
}
@media screen and (max-width: 600px ) {
  .what-we-do-heading {
    margin-bottom: 1em;
    margin-left: 0;
  }
  .what-we-do-heading h3 {
    font-size: 1.4em;
  }
  
}
@media screen and (max-width: 462px) {
  
}
.accordion-summary div:first-child {
  margin: 22px 0;
}
.accordion-summary:hover .accordion-title {
  /* color: #000; */
  -webkit-animation: white-rainbow 6s infinite;
  animation: white-rainbow 6s infinite;
}
.accordion-title {
  font-size: 1.75rem;
  line-height: 1.25;
  /* margin: 1rem auto .75rem; */
  color: #777;
  -webkit-transition: color 1s; /* Safari */
  transition: color 1s;
}
.accordion-title-number {
  font-size: 1.5rem;
}
.accordion-summary[aria-expanded="true"] .accordion-title {
  color: #191919;
}
.accordion-panel .accordion-content {
  display: block;
  text-align: left;
}
.accordion-panel .accordion-content p {
  font-size: 1.4rem;
  line-height: 160%;
}
.accordion-button button {
  margin-top: 1.8rem;
}
.panel-divider {
  width: 100%;
  height: 1px;
  background-color: #ccc;
}
.MuiExpansionPanel-root-1:before {
  display: none !important;
  opacity: 0 !important;
  background-color: rgb(255, 255, 255);
}
@media screen and (min-width: 1601px) {
  .accordion-title {
    font-size: 1.4rem;
    line-height: 1.25;
  }
  .accordion-title-number {
    font-size: 1.2rem;
  }
  .accordion-panel .accordion-content p {
    font-size: 1.2rem;
  }
  
}
@media screen and ( min-width: 1201px ) { 
}
@media screen and ( min-width: 801px ) and ( max-width: 1600px ) {
  .accordion-title {
    font-size: 1.2rem;
    line-height: 1.25;
  }
  .accordion-title-number {
    font-size: 1rem;
  }
  .accordion-panel .accordion-content p {
    font-size: 1rem;
  }
}
@media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
}
@media screen and ( max-width: 800px ) {
  .accordion-summary {
    padding-left: 0px !important;
  }
}
@media screen and ( min-width: 601px ) and ( max-width: 800px ) {
  .accordion-title {
    font-size: 1.1rem;
    line-height: 1.25;
  }
  .accordion-title-number {
    font-size: .95rem;
  }
  .accordion-panel .accordion-content p {
    font-size: 1rem;
  }
}
@media screen and ( max-width: 600px ) {
  
  .accordion-title {
    font-size: 1rem;
  }
  .accordion-title-number {
    font-size: .9rem;
  }
  
  .accordion-panel .accordion-content p {
    font-size: .8rem;
  }
  
}
.home-portfolio-wrapper {
  position:relative;
  width: 100%;
}
.home-portfolio-base-desktop > svg {
 grid-area: 1 / 1 / 10 / 6;
}
.home-portfolio-base-mobile {
  min-height: 400px;
  height: auto;
  width: 100%;
}
.home-portfolio-base-mobile h2 {
  font-size: 2.8rem;
  margin: 1rem auto 2.4rem;
}
.home-portfolio-base-mobile .jo-link-button-wrapper {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 8px 0px;
}
.home-portfolio-base-mobile .jo-link-button-wrapper a {
  display: inline;
  margin: auto;
}

@media screen and ( min-width: 1201px ) { 
  
}
@media screen and ( max-width: 1200px ) {
  
}
@media screen and ( min-width: 1001px ) {
  
}
@media screen and ( min-width: 801px ) {
  .home-portfolio-wrapper {
    margin: 150px auto;
  }
  .home-portfolio-base-mobile {
    display: none;
  }
  .home-portfolio-base-desktop {
    position: relative;
    display: grid;
    grid-template-columns: 31.5% 11.5% 14% 11.5% 31.5%;
    grid-template-rows: 9.29% 8.21% 23.21% 8.21% 2.14% 8.21% 23.21% 8.21% 9.29%;
    width: 80%;
    margin: auto;
    height: auto;
    max-width: 1400px;
    overflow: visible;
  }
  .home-portfolio-block {
    position: relative;
    height: 100%;
    /* background: #ccc; */
  }
  .home-portfolio-block-1 {
    grid-column:1;
    grid-row: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .home-portfolio-block-2 {
    grid-column: 3/6;
    grid-row: 1/4;
  }
  .home-portfolio-block-3 {
    grid-column: 1;
    grid-row: 3/6;
  }
  .home-portfolio-block-4 {
    grid-column: 5;
    grid-row: 5/8;
  }
  .home-portfolio-block-5 {
    grid-column: 1/4;
    grid-row: 7/10; 
  }
  .home-portfolio-block-6 {
    grid-column: 5;
    grid-row: 9;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }
}
@media screen and ( min-width: 801px ) and ( max-width: 1000px ) {
  .home-portfolio-base-desktop {
    grid-template-columns: 31.5% 11.5% 14% 11.5% 31.5%;
    grid-template-rows: 9.29% 8.21% 23.21% 8.21% 2.14% 8.21% 23.21% 8.21% 9.29%;
    width: 100%;
  }
}
@media screen and ( max-width: 800px ) {
  .home-portfolio-wrapper {
    margin: 80px auto 120px;
  }
  .home-portfolio-wrapper .home-section-content, .home-portfolio-base-desktop {
    display: none;
  }
  .home-portfolio-base-mobile {
    display: block;
    width: 100%;
  }
  
}
@media screen and ( min-width: 601px ) and ( max-width: 800px ) {
 
}
@media screen and ( max-width: 600px ) {
  .jo-transform-box {

  }
}
.carousel-container {
  position: relative;
  height: 400px;
  max-width: 800px;
  margin: 0 auto;
  touch-action: pan-y;
}
.carousel-track {
  display: -webkit-flex;
  display: flex;
  height: 100%;
}
.carousel-card {
  -webkit-flex: 0 0 340px;
          flex: 0 0 340px;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  font-weight: bold;
  color: white;
  padding: 2px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-perspective: 400px;
  -ms-perspective: 400px;
  -o-perspective: 400px;
  perspective: 400px;
}
.carousel-card-inner {
  position: relative;
  width: 346px;
  height: 396px;
  box-shadow: .75rem 1.25rem 2.5rem 0.35rem rgba(0,0,0,.1);
}
.carousel-card-image {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
}
.carousel-card-content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  background-color: rgba(0,0,0,.5);
}
.carousel-card-inner h3, .carousel-card-inner p {
  color: white;
}
.carousel-title {
  margin-top: 20px;
  font-size: 1.5em;
}

.carousel-text {
  padding: 1em;
  font-size: .9em;
  line-height: 150%;
  white-space: pre-wrap;
  text-align: left;
  font-family: sans-serif;
}

.carousel-pagination-wrapper {
  position: relative;
  margin: 60px auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  pointer-events: none;
  
}

.carousel-pagination {
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 180px;
  padding: 0;
}

.carousel-pagination li {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(50, 50, 50, 0.5);
}

.carousel-pagination .current {
  margin-top: -1px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f89c44;
  background-image: -webkit-linear-gradient(75deg, #ef6085, #f0ba45 );
  background-image: linear-gradient(15deg, #ef6085, #f0ba45 );
}
#home-section-four {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: 500px;
}
#home-section-four .home-blog-section {
  box-sizing: border-box;
  width: 95%; 
  max-width: 1600px;
  padding: 20px;
}
.home-blog-section h2 {
  text-align: left;
  font-size: 3.2em;
}
@media screen and (max-width: 600px) {
  .home-blog-section h2 {
    text-align: center;
  }
}
.jo-small-list-posts-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
@media screen and (max-width: 1000px) {
  .jo-small-list-posts-container {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
}
.jo-list-post-small {
  
  position: relative;
  width: 30%;
  height: auto;
  box-sizing: border-box;
  margin: 30px auto;
  background-size: cover;
  background-position: center;
  box-shadow: -.5rem .5rem 3.6rem 0.6rem rgba(0,0,0,.12);
}
.jo-list-post-small .jo-list-post-featured-img {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  padding: 0px;
  margin: 0px;
}
.jo-list-post-small-flex-container {
  
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 0px;
  display: -webkit-flex;
  display: flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  height: 100%;
}
.jo-list-post-small .jo-list-post-meta {
  width: 100%;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.jo-list-post-small .jo-list-post-title {
  text-align: left;
  padding-top: 15%;
  padding-right: 10px;
  box-sizing: border-box;
}
.jo-list-post-small .jo-list-post-read-more {
  text-align: right;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.jo-list-post-small .jo-list-post-read-more .jo-link-button-wrapper {
  position: relative;
  bottom: -10px;
  margin-right: -12px;
  z-index: 30;
}
.jo-list-post-small .black-box-text {

}
.jo-list-post-small .jo-link-button-wrapper{
  position: relative;
  left:0;
  /* width: auto; */
}
.jo-list-post-small h1 {
  font-size: 1.5rem;
  text-transform: uppercase; 
}
.jo-list-post-small .jo-list-post-small-date {
  margin-right: 0px;
  margin-top: 18px;
}
.jo-list-post-small .jo-list-post-small-date p {
  font-size: 1em;
}
.jo-list-post-small .jo-list-post-small-date .black-box-text {
  padding: 8px 0px;
  box-shadow: 12px 0 0 #191919, -12px 0 0 #191919;
}

@media screen and (min-width: 1001px) {
  .jo-list-post-small .jo-list-post-read-more {
    /* display: none; */
  }
  .jo-list-post-small .jo-list-post-title {
    margin-bottom: -10px;
  }
}
@media screen and ( max-width: 1000px ) { 
  .jo-list-post-small {
    width: 100%;
  }
  .jo-list-post-small .jo-list-post-small-date {
    margin-top: 28px;
  }
}
@media screen and (min-width: 601px) and ( max-width: 1000px ) { 
  .jo-list-post-small h1 {
    font-size: 2.8rem;
  }
  
}
@media screen and ( max-width: 600px ) { 
  .jo-list-post-small h1 {
    font-size: 1.5rem;
  }
}
#home-section-five {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: 500px;
}
#home-section-five .home-section-content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding-bottom: 150px;
}

#home-section-five .jo-contact-form-wrapper {
  box-sizing: border-box;
  width: 80%; 
  max-width: 1400px;
}
.jo-contact-form {
  position: relative;
}
.jo-contact-form input {
	outline: none;
	border: none;
}
.jo-contact-form h2 {
  font-size: 3em;
  text-align: left;
  margin-bottom: 1.5em;
}
.jo-contact-form textarea {
  outline: none;
  border: none;
}
.jo-contact-form textarea:focus, input:focus {
  border-color: transparent !important;
}
.jo-contact-form input:focus::-webkit-input-placeholder { color:transparent; }
.jo-contact-form input:focus:-moz-placeholder { color:transparent; }
.jo-contact-form input:focus::-moz-placeholder { color:transparent; }
.jo-contact-form input:focus:-ms-input-placeholder { color:transparent; }
.jo-contact-form textarea:focus::-webkit-input-placeholder { color:transparent; }
.jo-contact-form textarea:focus:-moz-placeholder { color:transparent; }
.jo-contact-form textarea:focus::-moz-placeholder { color:transparent; }
.jo-contact-form textarea:focus:-ms-input-placeholder { color:transparent; }
.jo-contact-form input::-webkit-input-placeholder { 
  font-weight: normal;
  color: #555555;
}
.jo-contact-form input:-moz-placeholder { 
 font-weight: normal;
  color: #555555;
}
.jo-contact-form input::-moz-placeholder { 
  font-weight: normal;
  color: #555555;
}
.jo-contact-form input:-ms-input-placeholder { 
 font-weight: normal;
  color: #555555;
}
.jo-contact-form textarea::-webkit-input-placeholder { 
  font-weight: normal;
  color: #555555;
}
.jo-contact-form textarea:-moz-placeholder { 
 font-weight: normal;
  color: #555555;
}
.jo-contact-form textarea::-moz-placeholder { 
  font-weight: normal;
  color: #555555;
}
.jo-contact-form textarea:-ms-input-placeholder { 
  font-weight: normal;
  color: #555555;
}

/*---------------------------------------------*/
.jo-contact-form .jo-link-button-wrapper {
  width: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

/*==================================================================
[ Form ]*/

.contact100-form {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-bottom: 68px;
}
.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #d9d9d9;
  padding-bottom: 13px;
  margin-bottom: 65px;
}
.rs1-wrap-input100 {
  width: calc((100% - 30px) / 2);
  display: inline-block;
}
.rs1-wrap-input100:first-child {
  margin-right:30px;
}
.jo-contact-form label {
  display: block;
  font-weight: normal;
  font-size: 16px;
  color: #999999;
  line-height: 1.5;
  padding-left: 5px;
  text-align: left;
}
.input100 {
  display: block;
  width: 100%;
  background: transparent;
  font-weight: 600;
  font-size: 2em;
  color: #555555;
  line-height: 1.2;
  padding: 0 5px;
}
.input100::-webkit-input-placeholder { /* WebKit browsers */
  font-size: .7em; /* 1em -> input font-size * 1 -> 40px * 1 = 40px */
}
.input100:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  font-size: .7em;
}
.input100::-moz-placeholder { /* Mozilla Firefox 19+ */
   font-size: .7em;
}
.input100:-ms-input-placeholder { /* Internet Explorer 10+ */
  font-size: .7em;
} 
.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}
.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background: #ef6085; /* Old browsers */ /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, #ef6085 1%,#f89c44 48%,#f0ba45 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #ef6085 1%,#f89c44 48%,#f0ba45 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ef6085', endColorstr='#f0ba45',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

/*---------------------------------------------*/
input.input100 {
  height: 40px;
}
textarea.input100 {
  min-height: 110px;
  padding-top: 9px;
  padding-bottom: 13px;
} 
.input100:focus + .focus-input100::before, .has-val + .focus-input100::before {
  width: 100%;
}
@media (max-width: 768px) {
  .rs1-wrap-input100 {
    width: 100%;
  }
}

/*------------------------------------------------------------------ [ Alert validate ]*/
.validate-input {
  position: relative;
}
.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #ef6085;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 58%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 2px;
  pointer-events: none;
  font-weight: normal;
  color: #ef6085;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
}
.alert-validate::after {
  content: "\F06A";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  color: #ef6085;
  font-size: 16px;
  top: 58%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 8px;
}
.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}
@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

/*---- Error and Success ----*/
.jo-contact-form-submission-message {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 10;
  background: rgba(255,255,255,.8);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 2rem;
  font-size: 1.8em;
  font-weight: bold;
}
.grecaptcha-badge {
  display: none !important;
}
.recaptcha-information {
  position: absolute;
  color: #bbb;
  margin-top: -42px;
  text-align: left;
}
.jo-contact-form .recaptcha-information a {
  color: #999;
  text-transform: none !important;
}

.home {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;
    height: auto;
    width: 100%;
}
.home-section {
    width: 100%;
    height: auto;
    
}
.home-section-content {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 30px;
}
@media screen and ( min-width: 1201px ) {
    .home-section-content {
      padding: 80px;
    }
  }
@media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
    .home-section-content {
      padding: 40px;
    }
}



/* from home section */
.home-section-content h2 {
text-align: left;
font-size: 3.2em;
margin-bottom: .7em;
}
.home-section-content p {
font-size: 1.2em;
line-height: 1.6em;
font-weight: 300;
text-align: left;
}
.home-section-content a {
font-size: 1em;
margin-top: 1.8em;
color: #191919;
text-transform: uppercase;
text-decoration: none;
-webkit-transition: color .5s ease-in-out, border-bottom .5s ease-in-out;
transition: color .5s ease-in-out, border-bottom .5s ease-in-out;
border-bottom: 2px solid rgba(0,0,0,0);
}
  
/* @media screen and (max-width: 800px) {
    .home-section-content {
        width: 100%;
    }    
} */

/* Contact Form Section */
.jo-contact-form-wrapper {
    display: block;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    height: auto;

}

.contact-page {
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding: 80px 0px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.contact-page .jo-post-content-wrapper {
  text-align: center;
  padding: 40px 0;
}
.about-page {
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding-bottom: 40px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.about-page h1, .about-page p {
  text-align: left;
}
.about-page .jo-work-header-content {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
  padding: 0px;
}
.about-page .jo-work-header-content h1.jo-work-header-title {
  text-align: center;
}
@media screen and (min-width: 800px) {
  .about-page section {
    padding: 40px 0px;
  }
  
}
.jo-work-header {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  width: 100%;
  height: 40vh;
  max-height: 800px;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
}
.jo-work-header-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
} 
.jo-work-header-content {
  width: auto;
  height: auto;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: left;
}
.jo-work-header-content h1.jo-work-header-title {
  color: #fff;
  font-weight: bold;
  z-index: 10;
  text-align: left;
}
.jo-work-header-content h3 {
  color: #fff;
  font-weight: bold;
  z-index: 10;
  text-aling: left;
}
@media screen and ( min-width: 1201px ) { 
  .jo-work-header-content {
    padding: 0px 40px 60px 60px;
  }
  .jo-work-header h1 {
    font-size: 4.5rem;
    line-height: 1.05;
  }
  .jo-work-header h3 {
    font-size: 2.2rem;
    line-height: 1.25;
  }
}
@media screen and ( max-width: 1200px ) {
  
}
@media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
  .jo-work-header-content {
    padding: 0px 20px 40px 40px;
  }
  .jo-work-header h1 {
    font-size: 3.6rem;
    line-height: 1.05;
  }
  .jo-work-header h2 {
    font-size: 2.5rem;
    line-height: 1.25;
  }
  .jo-work-header h3 {
    font-size: 1.75rem;
    line-height: 1.25;
  }
  .jo-work-header h4 {
    line-height: 1.22222222;    
  }
  .jo-work-header p {
    font-size: 1.4rem;
    line-height: 160%;    
  }
}
@media screen and ( min-width: 800px ) {
  
}
@media screen and ( min-width: 601px ) and ( max-width: 800px ) {
  .jo-work-header {
    height: 40vh;
  }
  .jo-work-header-content {
    padding: 0px 10px 30px 30px;
  }
  .jo-work-header h1 {
    font-size: 2.7rem;
    /* 2.5x body copy size = 40px */
    line-height: 1.125;
  }
  .jo-work-header h2 {
    font-size: 2.4rem;
    /* 2x body copy size = 32px */
    line-height: 1.25;
  }
  .jo-work-header h3 {
    font-size: 1.8rem;
    /* 1.5x body copy size = 24px */
    line-height: 1.25;
  }
  .jo-work-header h4 {
    line-height: 1.22222222;
    /* (22px / 18px */
  }
  .jo-work-header p {
    font-size: 1.2rem;
    line-height: 155%;
  }
}
@media screen and ( max-width: 600px ) {
  .jo-work-header {
    height: 40vh;
  }
  .jo-work-header-content {
    padding: 0px 5px 25px 25px;
  }
  .jo-work-header h1 {
    font-size: 2.5rem;
    /* 2.5x body copy size = 40px */
    line-height: 1.125;
  }
  .jo-work-header h2 {
    font-size: 2rem;
    /* 2x body copy size = 32px */
    line-height: 1.25;
  }
  .jo-work-header h3 {
    font-size: 1.5rem;
    /* 1.5x body copy size = 24px */
    line-height: 1.25;
  }
  .jo-work-header h4 {
    line-height: 1.22222222;
    /* (22px / 18px */
  }
  .jo-work-header p {
    font-size: 1rem;
  }
}
.single-project {
  width: 100%;
  max-width: 100vw;
}
.single-project article {
  margin-top: 0;
}

.jo-project-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction:column;
          flex-direction:column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding-bottom: 120px;
}
.jo-project-content-header {
  position:relative;
  width: 100%;
  height: auto;
  min-height: 100vh;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
}
.jo-project-mockups {
  position: relative;
  width: 55%;
}
.jo-project-mockups .jo-mockup-desktop {
  height: auto;
  z-index: 5;
}
.jo-project-mockups .jo-mockup-desktop:hover {
  z-index: 15;
}
.jo-project-mockups .jo-mockup-mobile {
  z-index: 10;
}
.jo-project-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 45%;
  box-sizing: border-box;
  padding: 20px 40px;
}
.jo-project-info h1 {
  font-size: 3.6rem;
  margin: .5rem auto;
}
.jo-project-info h3 {
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 130%;
  margin: .5rem auto;
}
.jo-project-info a.jo-project-header-project-link {
  margin: 1rem auto;
}
.jo-project-content .jo-post-content-wrapper {
  margin-top: 20px;
  max-width: 900px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.jo-project-technologies {
  font-size: .8rem;
}
.jo-project-client {
  font-size: 1em;
}
.jo-project-client .jo-project-client-text {
  /* font-weight: bold; */
}
.jo-project-info .jo-project-info-links {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.jo-project-info .jo-project-info-links.jo-project-info-links-double-row {
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 340px;
  margin: .6em auto;
  
}
.jo-project-info-links-double-row div:first-child, .jo-project-info-links-double-row div:nth-child(2){
  -webkit-flex-grow: 1;
          flex-grow: 1;
  width: 100%;
}
.jo-project-info .jo-project-info-links div:first-child {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.jo-project-info .jo-project-info-links div:first-child div {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}
.jo-project-info-links-double-row div:nth-child(2) .jo-link-button button, .jo-project-info-links-double-row div:first-child .jo-link-button button {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.jo-project-info-links-double-row div:nth-child(2) .jo-link-button-wrapper,
.jo-project-info-links-double-row div:nth-child(2) .jo-link-button-wrapper a,
.jo-project-info-links-double-row div:nth-child(2) .jo-link-button-wrapper button,
.jo-project-info .jo-project-info-links div:first-child .jo-link-button-wrapper,
.jo-project-info .jo-project-info-links div:first-child .jo-link-button-wrapper a,
.jo-project-info .jo-project-info-links div:first-child .jo-link-button-wrapper button {
  width: 100%;
  box-sizing: border-box;
}
.jo-project-info-links div.jo-link-button-wrapper {
  padding: 10px 5px;
}
.jo-project-info .jo-link-button-wrapper {
  display: inline-block;
}

/* Screenshots in content */
.wp-caption {
  max-width: 100%;
  box-sizing: border-box;
  padding-right: 20px;
  margin: 2em auto;
}
.jo-project-content p.wp-caption-text {
  font-size: 1em;
  margin: .6em auto;
  text-align: center;
}
img.jo-project-content-screenshot {
  width: auto;
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
  /* box-shadow: 1.4rem 2.4rem 4.8rem 0.6rem rgba(0,0,0,.05); */
  font-size: 0px;
  background: #ddd;
  border-radius: 10px;
  padding: 20px;
  overflow: hidden;
}
/* End Screenshots in content */


@media screen and ( min-width: 1201px ) { 
  .jo-project-content-content {
    padding: 0px 40px 60px 60px;
  }
  .jo-project-content .jo-post-content-wrapper h1 {
    font-size: 4.5rem;
    line-height: 1.05;
  }
  .jo-project-content .jo-post-content-wrapper h3 {
    font-size: 2.2rem;
    line-height: 1.25;
  }
}
@media screen and ( max-width: 1200px ) {
  
}
@media screen and ( min-width: 1001px ) {
  .jo-project-mockups .jo-mockup-desktop {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 10%;
    width: 75%;
    height: auto;
    z-index: 5;
  }
  .jo-project-mockups .jo-mockup-mobile {
    position: absolute;
    width: 30%;
    height: auto;
    top: 70%;
    -webkit-transform: translateY(-70%);
            transform: translateY(-70%);
    right: 2%;
  }
  
  
}
@media screen and ( max-width: 1000px ) {
  .jo-project-content-header {
    height: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .jo-project-mockups, .jo-project-info {
    display: block;
    width: 100%;
    margin: 40px auto;
  }
  .jo-project-info {
    padding: 20px 40px;
    max-width: 700px;
  }
  .jo-project-mockups .jo-mockup-desktop {
    position: relative;
    width: 60%;
    left: 15%;
    height: auto;
    margin: 60px 0 0 0;
  }
  .jo-project-mockups .jo-mockup-mobile {
    position: absolute;
    width: 25%;
    bottom: -30px;
    right: 10%;
  }
}
@media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
  .jo-project-content-content {
    padding: 0px 20px 40px 40px;
  }
  .jo-project-content.jo-post-content-wrapper h1 {
    font-size: 3.6rem;
    line-height: 1.05;
  }
  .jo-project-content .jo-post-content-wrapper h2 {
    font-size: 2.5rem;
    line-height: 1.25;
  }
  .jo-project-content .jo-post-content-wrapper h3 {
    font-size: 1.75rem;
    line-height: 1.25;
  }
  .jo-project-content .jo-post-content-wrapper h4 {
    line-height: 1.22222222;    
  }
  .jo-project-content .jo-post-content-wrapper p {
    font-size: 1.4rem;
    line-height: 160%;    
  }
  .jo-project-content p.wp-caption-text {
    font-size: 1em;
  }
}
@media screen and ( min-width: 801px ) and ( max-width: 1000px ) {
  .jo-project-content .jo-post-content-wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media screen and ( max-width: 800px ) {
  img.jo-project-content-screenshot {
    padding: 15px;
  }
}
@media screen and ( min-width: 601px ) and ( max-width: 800px ) {
  .jo-project-info h1 {
    font-size: 2.8rem;
    margin: .5rem auto;
  }
  .jo-project-info h3 {
    font-size: 1.1rem;
    font-weight: normal;
    line-height: 130%;
    margin: .7rem auto;
  }
  .jo-project-content-content {
    padding: 0px 10px 30px 30px;
  }
  .jo-project-content .jo-post-content-wrapper {
    padding: 20px 60px;
  }
  .jo-project-content .jo-post-content-wrapper h1 {
    font-size: 2.7rem;
    /* 2.5x body copy size = 40px */
    line-height: 1.125;
  }
  .jo-project-content .jo-post-content-wrapper h2 {
    font-size: 2.4rem;
    /* 2x body copy size = 32px */
    line-height: 1.25;
  }
  .jo-project-content .jo-post-content-wrapper h3 {
    font-size: 1.8rem;
    /* 1.5x body copy size = 24px */
    line-height: 1.25;
  }
  .jo-project-content .jo-post-content-wrapper h4 {
    line-height: 1.22222222;
    /* (22px / 18px */
  }
  .jo-project-content .jo-post-content-wrapper p {
    font-size: 1.2rem;
    line-height: 155%;
  }
  .jo-project-content p.wp-caption-text {
    font-size: .9em;
  }
}
@media screen and ( max-width: 600px ) {
  
  .jo-project-mockups .jo-mockup-desktop {
    width: 80%;
    left: 7%;
    margin: 45px 0 0 0;
  }
  .jo-project-mockups .jo-mockup-mobile {
    width: 35%;
    bottom: -30px;
    right: 5%;
  }
  .jo-project-mockups, .jo-project-info {
    margin: 25px auto;
  }

  .jo-project-info {
    padding: 20px 20px;
  }
  .jo-project-info h1 {
    font-size: 2.4rem;
    margin: .4rem auto;
  }
  .jo-project-info h3 {
    font-size: 1rem;
    font-weight: normal;
    line-height: 130%;
    margin: .5rem auto;
  }

  .jo-project-content .jo-post-content-wrapper .wp-caption {
    padding-right: 0px;
  }
  .jo-project-content-content {
    padding: 0px 5px 25px 25px;
  }
  .jo-project-content .jo-post-content-wrapper h1 {
    font-size: 2.5rem;
    /* 2.5x body copy size = 40px */
    line-height: 1.125;
  }
  .jo-project-content .jo-post-content-wrapper h2 {
    font-size: 2rem;
    /* 2x body copy size = 32px */
    line-height: 1.25;
  }
  .jo-project-content .jo-post-content-wrapper h3 {
    font-size: 1.5rem;
    /* 1.5x body copy size = 24px */
    line-height: 1.25;
  }
  .jo-project-content .jo-post-content-wrapper h4 {
    line-height: 1.22222222;
    /* (22px / 18px */
  }
  .jo-project-content .jo-post-content-wrapper p {
    font-size: 1rem;
  }
  .jo-project-content p.wp-caption-text {
    font-size: .8em;
  }
}
.jo-site-mockup{
  width: auto;
  max-width: 100%;
  height: auto;
  box-shadow: 1.4rem 2.4rem 4.8rem 0.6rem rgba(0,0,0,.12);
  font-size: 0px;
  border-radius: 5px;
  overflow: hidden;
}
.jo-site-mockup.jo-mockup-desktop .jo-site-mockup-container {
  padding-bottom: 106.67%;
}
.jo-site-mockup.jo-mockup-mobile .jo-site-mockup-container {
  padding-bottom: 156.09%;
}
.jo-site-mockup.jo-mockup-mobile {
  background: #ddd;
  border-radius: 10px;
  padding: 20px 15px;
  box-shadow: -1.4rem 2.4rem 4.8rem 0.6rem rgba(0,0,0,.12);
}
.jo-site-mockup-header {
  width: 100%;
  height: auto;
}
.jo-site-mockup-container {
  width: 100%;
  height: 0px;
  position: relative;
  background: #999;
  overflow:hidden;
}
.jo-mockup-mobile .jo-site-mockup-container {
  background: #999;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.jo-site-mockup-container img {
  width: 100%;
  height: auto;
  left:0;
  top:0;
  position: absolute;
}
.jo-site-mockup-container svg {
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.jo-mockup-desktop .jo-site-mockup-container svg {
  width: 50%;
}
.jo-mockup-mobile .jo-site-mockup-container svg {
  width: 70%;
}
.jo-site-mockup-container svg path {
  fill: #aaa;
}
@media screen and (max-width: 1000px) {
  .jo-site-mockup.jo-mockup-mobile {
    border-radius: 8px;
    padding: 15px 12px;
    box-shadow: -.5rem .5rem 3.6rem 0.6rem rgba(0,0,0,.12);
  }
}
@media screen and (max-width: 600px) {
  .jo-site-mockup.jo-mockup-mobile {
    border-radius: 7px;
    padding: 13px 10px;
    box-shadow: -.5rem .5rem 3.6rem 0.3rem rgba(0,0,0,.12);
  }
}
/* Nav */
#main-nav {
  position: fixed;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  box-sizing: border-box;
  left: 0%;
  z-index: 10;
  height: 100%;
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  padding: 80px 20px 60px;
  opacity: 0;
  background: #191919;
  overflow: hidden;
  color: #fff;
  -webkit-transition: opacity .3s ease-in-out; /* Safari */
  transition: opacity .3s ease-in-out;
  z-index: 0;
}
#main-nav.initialized {
  opacity: 1;
  animation-name: fade-out-right;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  -webkit-animation-duration: 0.4s;
  -webkit-animation-name: fade-out-right;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}
#main-nav.front {
  z-index: 100;
}
#main-nav.toggled {
  animation-duration: 0.4s;
  animation-name: fade-in-left-full;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  -webkit-animation-duration: 0.4s;
  -webkit-animation-name: fade-in-left-full;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 1;
}
#main-nav ul {
  opacity: 1;
  list-style-type: none;
  text-align: center;
  margin: auto;
  padding: 0; 
  z-index: 100;
}
@-webkit-keyframes fade-out-right {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 1;
  }
}
@keyframes fade-out-right {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-left-full {
  from {
    opacity: 1;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left-full {
  from {
    opacity: 1;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
.main-nav ul li {
  font-size: 6em;
  line-height: 105%;
}
.main-nav ul li a {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: color .5s; /* Safari */
  transition: color .5s;
  font-family: 'Geomanist', 'OpenSans', 'Helvetica', sans-serif;
  font-weight: 600;
}
.main-nav ul li a:hover, .main-nav ul li a:active, .main-nav ul li a:focus {
  -webkit-animation: white-rainbow 5s infinite;
  animation: white-rainbow 5s infinite;
}
.jo-nav-shape {
  width: 40%;
  max-width: 400px;
  height: auto;
  position: absolute; 
}
.jo-nav-shape path, .jo-nav-shape polygon, .jo-nav-shape rect {
  fill: #222;
}
.jo-nav-shape-2 {
  bottom: 3%;
  right: 3%;
}
.jo-nav-shape-5 {
  left: 4%;
  bottom: 20%;
}
.jo-nav-shape.jo-nav-shape-5 {
  width: 30%;
  max-width: 300px;
}

@media screen and ( min-width: 601px ) and ( max-width: 800px ) {
  .main-nav ul li {
    font-size: 5em;
  }
  #main-nav ul {
    margin: auto 0 auto 2em;
  }
  .jo-nav-shape-5 {
    left: 50%;
    top: 10%;
  }
}
@media screen and ( max-width: 600px ) {
  .main-nav ul li {
    font-size: 3.6em;
  }
  #main-nav ul {
    margin: auto 0 auto 1.2em;
  }
  .jo-nav-shape-5 {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  #main-nav {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
  #main-nav ul {
    text-align: left;
  }
}
/* Header */
#header {
  display: block;
  box-sizing: border-box;
  position: fixed;
  top: 0px;
  width: 100%;
  height: 72px;
  padding: 14px 10px;
  z-index: 150;
  background-color: rgba(25,25,25,0);
  -webkit-transition: background-color .3s ease;
  transition: background-color .3s ease;
}
.solid-header #header {
  background-color: rgba(25,25,25,1);
  box-shadow: 1px 1px 5px rgba(0,0,0,.1);
}
.app-menu-toggled #header {
  /* background:none; */
}
#header a.site-title {
  display: inline;
  position: relative;
  float: left;
  opacity: 1; 
  color: #fff;
  height: auto;
  margin-top: 8px;
  margin-left: 10px;
}
#header a.site-title h1 {
  position: absolute;
  left: 30px;
  display: inline;
  margin-left: 6px;
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transition: color .3s ease; /* Safari */
  transition: color .3s ease;
}
#header .site-title:hover .jo-logo-wrapper {
  -webkit-transform: rotate(375deg);
          transform: rotate(375deg);
  padding-bottom: 44px;
  width: 44px;
  top: -7px;
  left: -2px;
}
.site-title .jo-logo-wrapper {
  left: 0;
  top: -4px;
  padding-bottom: 36px;
  width: 34px;
  display: inline;
  -webkit-transition: top .2s ease-in-out, left .2s ease-in-out, padding-bottom .2s ease-in-out, .2s width ease-in-out, -webkit-transform .2s ease-in-out;
  -webkit-transition: top .2s ease-in-out, left .2s ease-in-out, padding-bottom .2s ease-in-out, width .2s ease-in-out, -webkit-transform .2s ease-in-out;
  transition: top .2s ease-in-out, left .2s ease-in-out, padding-bottom .2s ease-in-out, width .2s ease-in-out, -webkit-transform .2s ease-in-out;
  transition: top .2s ease-in-out, left .2s ease-in-out, padding-bottom .2s ease-in-out, width .2s ease-in-out, transform .2s ease-in-out;
  transition: top .2s ease-in-out, left .2s ease-in-out, padding-bottom .2s ease-in-out, width .2s ease-in-out, transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
}

/* Menu Toggle */
button#nav-icon {
  border: none !important;
  outline: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
button#nav-icon {color:#000;}      /* unvisited link */
button#nav-icon:visited {color:#000;}  /* visited link */
button#nav-icon, button#nav-icon:hover, button#nav-icon:active, button#nav-icon:focus {
  border: none;
  color: none;
}
button#nav-icon:hover span {
  -webkit-animation: bg-rainbow 7s infinite;
  animation: bg-rainbow 7s infinite;
}
#nav-icon {
  width: 24px;
  height: 24px;
  position: relative;
  margin: 12px 10px;
  float:right;
  padding: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .3s ease-in-out, margin 1s ease;
  transition: .3s ease-in-out, margin 1s ease;
  cursor: pointer;
}
#nav-icon p {
  position: absolute;
  margin: 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  right: 28px;
  top: 0px;
  -webkit-transition: color .5s ease-in-out;
  transition: color .5s ease-in-out;
}
/* .app-menu-dark #nav-icon span {
  background-color: #fff;
}
.app-menu-dark #nav-icon p {
  color: #000;
} */
.app-menu-toggled #nav-icon span, .solid-header #nav-icon span, .keep-toggler-white #header #nav-icon span {
  background-color: #fff;
}
#nav-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 24px;
  border-radius: 2px;
  background-color: #191919;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: background-color .5s ease-in-out;
  transition: background-color .5s ease-in-out;
}
#nav-icon span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}
#nav-icon span:nth-child(2) {
  top: 9px;
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: opacity .15s ease-in-out;
  transition: opacity .15s ease-in-out;
}
#nav-icon span:nth-child(3) {
  top: 18px;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}
#nav-icon span:nth-child(1), #nav-icon span:nth-child(3) {
  -webkit-transition: -webkit-transform .15s ease-in-out, top .15s ease-in-out, left .15s ease-in-out;
  -webkit-transition: top .15s ease-in-out, left .15s ease-in-out, -webkit-transform .15s ease-in-out;
  transition: top .15s ease-in-out, left .15s ease-in-out, -webkit-transform .15s ease-in-out;
  transition: transform .15s ease-in-out, top .15s ease-in-out, left .15s ease-in-out;
  transition: transform .15s ease-in-out, top .15s ease-in-out, left .15s ease-in-out, -webkit-transform .15s ease-in-out;
}
#nav-icon.toggled span:nth-child(1), #nav-icon.toggled span:nth-child(3) {
  width: 27px;
  width: 27px;
}
#nav-icon.toggled span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -1px;
  left: 2px;
}
#nav-icon.toggled span:nth-child(2) {
  opacity: 0;
}
#nav-icon.toggled span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 18px;
  left: 2px;
}
@-webkit-keyframes bg-rainbow {
  0% {background-color: #ffffff;}
  15% {background-color: #f0ba45;}
  40% {background-color: #f89c44;}
  65% {background-color: #ef6085;}
  90% {background-color: #cd5fa1;}
  100% {background-color: #ffffff;}
}
@keyframes bg-rainbow {
  0% {background-color: #ffffff;}
  15% {background-color: #f0ba45;}
  40% {background-color: #f89c44;}
  65% {background-color: #ef6085;}
  90% {background-color: #cd5fa1;}
  100% {background-color: #ffffff;}
}
button#nav-icon:hover p, .app-menu-toggled button#nav-icon p {
  -webkit-animation: p-rainbow 7s infinite;
  animation: p-rainbow 7s infinite;
}
@-webkit-keyframes p-rainbow {
  0% {color: #ffffff;stroke:#ffffff;}
  15% {color: #f0ba45;stroke:#f0ba45;}
  40% {color: #f89c44;stroke:#f89c44;}
  65% {color: #ef6085;stroke:#ef6085;}
  90% {color: #cd5fa1;stroke:#cd5fa1;}
  100% {color: #ffffff;stroke:#ffffff;}
}
@keyframes p-rainbow {
  0% {color: #ffffff;stroke:#ffffff;}
  15% {color: #f0ba45;stroke:#f0ba45;}
  40% {color: #f89c44;stroke:#f89c44;}
  65% {color: #ef6085;stroke:#ef6085;}
  90% {color: #cd5fa1;stroke:#cd5fa1;}
  100% {color: #ffffff;stroke:#ffffff;}
}

.jo-logo-wrapper {
  position: relative;
  padding-bottom: 85%;
  left: 8%;
  width: 85%;
  height:0;
  -webkit-transform: rotate(15deg);
          transform: rotate(15deg);
  float: left;
}
.jo-logo {
  position: absolute;
  width: 100%;
  height: 100%;
}
.jo-logo > svg {
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
}
footer {
  position: relative;
  height: 250px;;
  min-height: 200px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: #191919;
  color: #fff;
  z-index: 75;
}
footer .jo-map-wrapper div {
  color: #191919;
}
footer .jo-contact-wrapper {
  box-sizing: border-box;
  height: 100%;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  padding: 40px;
  text-align: left;
}
footer .jo-contact-wrapper h3 {
  color: #fff;
  font-size: 3.2em;
  text-transform: uppercase;
}
.jo-contact-info {
  margin-top: 20px;
}
.jo-footer-shape-section {
  position: absolute;
  box-sizing: border-box;
  width: 30%;
  max-width: 150px;
  height: auto;
  right: 30px;
  bottom: 30px;
}
.jo-footer-shape-section svg path {
  fill: #fff;
}
/* Media Queries */
@media screen and ( min-width: 1201px ) {
}
@media screen and ( max-width: 1200px ) {
}
@media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
}
@media screen and ( min-width: 801px ) {
  footer .jo-contact-wrapper {

  }
}
@media screen and ( max-width: 800px ) {
  footer .jo-map-wrapper {
    display: none;
  }
}
@media screen and ( min-width: 601px ) and ( max-width: 800px ) {
}
@media screen and ( max-width: 600px ) {
  footer .jo-contact-wrapper {
    padding: 20px;
  }
  footer .jo-footer-shape-section { 
    bottom: 51px;
  }
}
.jo-contact-wrapper {
  display: inline-block;
  text-transform: uppercase;
  
}
.jo-contact-wrapper h3 {
  font-weight: bold;
}
.jo-contact-info address, .jo-contact-info a, .jo-contact-info p {
  line-height: 160%;
  font-weight: 500;
  font-size: .8em;
  color: #fff;
  text-decoration: none;
}
.jo-social-icons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  font-size: 0;
  list-style: none;
  margin-top: 18px;
  text-align: left;
  
}
.jo-social-icons li {
  display: inline-block;
  margin: 2px;
}
.jo-social-icons a, .jo-social-icons svg {
  display: block;
}
.jo-social-icons a {
  position: relative;
  height: 20px;
  height: 2.0rem;
  width: 20px;
  width: 2.0rem;
}
.jo-social-icons svg {
  height: 100%;
  width: 100%;
}
a.facebook {
  position: relative;
  width: 32px;
  height: 32px;  
}
.facebook svg {
  position: absolute;
  left: 4px;
  top: 4px;
  width: 24px;
  height: 24px;
  margin: auto;
}
.jo-social-icons em {
  font-size: 14px;
  line-height: 1.5;
  margin-top: -0.75em;
  position: absolute;
  text-align: center;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
}

.icon-17:hover, .icon-13:hover, .icon-15:hover, .icon-26:hover {
  border-radius: 2px;
  /* color: #0e1a25;
  fill: #0e1a25; */
  -webkit-transform: scale(1.25) rotate(-15deg);
          transform: scale(1.25) rotate(-15deg);
  -webkit-transition: background-color 0.5s, -webkit-transform 0.5s ease-out;
  transition: background-color 0.5s, -webkit-transform 0.5s ease-out;
  transition: background-color 0.5s, transform 0.5s ease-out;
  transition: background-color 0.5s, transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
}
.icon-17 {
  color: #cd5fa1;
  fill: #cd5fa1;
}
.icon-17:hover {
  /* background: #cd5fa1; */
}
.icon-13 {
  color: #f0ba45;
  fill: #f0ba45;
}
.icon-13:hover {
  /* background: #f0ba45; */
}
.icon-15 {
  color: #f89c44;
  fill: #f89c44;
}
.icon-15:hover {
  /* background: #f89c44; */
}
.icon-26 {
  color: #ef6085;
  fill: #ef6085;
}
.icon-26:hover {
  /* background: #ef6085; */
}


.jo-map-wrapper {
  position: relative;
  width: 45%;
  min-width: 200px;
  max-width: 600px;
  height: 100%;
  min-height: 100%;
}
#map {
  width: 100%;
  height: 100%;
}

