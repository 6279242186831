.jo-list-post-small {
  
  position: relative;
  width: 30%;
  height: auto;
  box-sizing: border-box;
  margin: 30px auto;
  background-size: cover;
  background-position: center;
  box-shadow: -.5rem .5rem 3.6rem 0.6rem rgba(0,0,0,.12);
}
.jo-list-post-small .jo-list-post-featured-img {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  padding: 0px;
  margin: 0px;
}
.jo-list-post-small-flex-container {
  
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 0px;
  display: flex;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.jo-list-post-small .jo-list-post-meta {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
}
.jo-list-post-small .jo-list-post-title {
  text-align: left;
  padding-top: 15%;
  padding-right: 10px;
  box-sizing: border-box;
}
.jo-list-post-small .jo-list-post-read-more {
  text-align: right;
  height: auto;
  display: flex;
  justify-content: flex-end;
}
.jo-list-post-small .jo-list-post-read-more .jo-link-button-wrapper {
  position: relative;
  bottom: -10px;
  margin-right: -12px;
  z-index: 30;
}
.jo-list-post-small .black-box-text {

}
.jo-list-post-small .jo-link-button-wrapper{
  position: relative;
  left:0;
  /* width: auto; */
}
.jo-list-post-small h1 {
  font-size: 1.5rem;
  text-transform: uppercase; 
}
.jo-list-post-small .jo-list-post-small-date {
  margin-right: 0px;
  margin-top: 18px;
}
.jo-list-post-small .jo-list-post-small-date p {
  font-size: 1em;
}
.jo-list-post-small .jo-list-post-small-date .black-box-text {
  padding: 8px 0px;
  box-shadow: 12px 0 0 #191919, -12px 0 0 #191919;
}

@media screen and (min-width: 1001px) {
  .jo-list-post-small .jo-list-post-read-more {
    /* display: none; */
  }
  .jo-list-post-small .jo-list-post-title {
    margin-bottom: -10px;
  }
}
@media screen and ( max-width: 1000px ) { 
  .jo-list-post-small {
    width: 100%;
  }
  .jo-list-post-small .jo-list-post-small-date {
    margin-top: 28px;
  }
}
@media screen and (min-width: 601px) and ( max-width: 1000px ) { 
  .jo-list-post-small h1 {
    font-size: 2.8rem;
  }
  
}
@media screen and ( max-width: 600px ) { 
  .jo-list-post-small h1 {
    font-size: 1.5rem;
  }
}