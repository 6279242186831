footer {
  position: relative;
  height: 250px;;
  min-height: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #191919;
  color: #fff;
  z-index: 75;
}
footer .jo-map-wrapper div {
  color: #191919;
}
footer .jo-contact-wrapper {
  box-sizing: border-box;
  height: 100%;
  flex-grow: 1;
  padding: 40px;
  text-align: left;
}
footer .jo-contact-wrapper h3 {
  color: #fff;
  font-size: 3.2em;
  text-transform: uppercase;
}
.jo-contact-info {
  margin-top: 20px;
}
.jo-footer-shape-section {
  position: absolute;
  box-sizing: border-box;
  width: 30%;
  max-width: 150px;
  height: auto;
  right: 30px;
  bottom: 30px;
}
.jo-footer-shape-section svg path {
  fill: #fff;
}
/* Media Queries */
@media screen and ( min-width: 1201px ) {
}
@media screen and ( max-width: 1200px ) {
}
@media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
}
@media screen and ( min-width: 801px ) {
  footer .jo-contact-wrapper {

  }
}
@media screen and ( max-width: 800px ) {
  footer .jo-map-wrapper {
    display: none;
  }
}
@media screen and ( min-width: 601px ) and ( max-width: 800px ) {
}
@media screen and ( max-width: 600px ) {
  footer .jo-contact-wrapper {
    padding: 20px;
  }
  footer .jo-footer-shape-section { 
    bottom: 51px;
  }
}