.carousel-container {
  position: relative;
  height: 400px;
  max-width: 800px;
  margin: 0 auto;
  touch-action: pan-y;
}
.carousel-track {
  display: flex;
  height: 100%;
}
.carousel-card {
  flex: 0 0 340px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  color: white;
  padding: 2px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-perspective: 400px;
  -moz-perspective: 400px;
  -ms-perspective: 400px;
  -o-perspective: 400px;
  perspective: 400px;
}
.carousel-card-inner {
  position: relative;
  width: 346px;
  height: 396px;
  box-shadow: .75rem 1.25rem 2.5rem 0.35rem rgba(0,0,0,.1);
}
.carousel-card-image {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
}
.carousel-card-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  background-color: rgba(0,0,0,.5);
}
.carousel-card-inner h3, .carousel-card-inner p {
  color: white;
}
.carousel-title {
  margin-top: 20px;
  font-size: 1.5em;
}

.carousel-text {
  padding: 1em;
  font-size: .9em;
  line-height: 150%;
  white-space: pre-wrap;
  text-align: left;
  font-family: sans-serif;
}

.carousel-pagination-wrapper {
  position: relative;
  margin: 60px auto;
  display: flex;
  justify-content: center;
  pointer-events: none;
  
}

.carousel-pagination {
  list-style: none;
  display: flex;
  justify-content: space-around;
  width: 180px;
  padding: 0;
}

.carousel-pagination li {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(50, 50, 50, 0.5);
}

.carousel-pagination .current {
  margin-top: -1px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f89c44;
  background-image: linear-gradient(15deg, #ef6085, #f0ba45 );
}