.accordion-summary div:first-child {
  margin: 22px 0;
}
.accordion-summary:hover .accordion-title {
  /* color: #000; */
  -webkit-animation: white-rainbow 6s infinite;
  -ms-animation: white-rainbow 6s infinite;
  -o-animation: white-rainbow 6s infinite;
  animation: white-rainbow 6s infinite;
}
.accordion-title {
  font-size: 1.75rem;
  line-height: 1.25;
  /* margin: 1rem auto .75rem; */
  color: #777;
  -webkit-transition: color 1s; /* Safari */
  transition: color 1s;
}
.accordion-title-number {
  font-size: 1.5rem;
}
.accordion-summary[aria-expanded="true"] .accordion-title {
  color: #191919;
}
.accordion-panel .accordion-content {
  display: block;
  text-align: left;
}
.accordion-panel .accordion-content p {
  font-size: 1.4rem;
  line-height: 160%;
}
.accordion-button button {
  margin-top: 1.8rem;
}
.panel-divider {
  width: 100%;
  height: 1px;
  background-color: #ccc;
}
.MuiExpansionPanel-root-1:before {
  display: none !important;
  opacity: 0 !important;
  background-color: rgb(255, 255, 255);
}
@media screen and (min-width: 1601px) {
  .accordion-title {
    font-size: 1.4rem;
    line-height: 1.25;
  }
  .accordion-title-number {
    font-size: 1.2rem;
  }
  .accordion-panel .accordion-content p {
    font-size: 1.2rem;
  }
  
}
@media screen and ( min-width: 1201px ) { 
}
@media screen and ( min-width: 801px ) and ( max-width: 1600px ) {
  .accordion-title {
    font-size: 1.2rem;
    line-height: 1.25;
  }
  .accordion-title-number {
    font-size: 1rem;
  }
  .accordion-panel .accordion-content p {
    font-size: 1rem;
  }
}
@media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
}
@media screen and ( max-width: 800px ) {
  .accordion-summary {
    padding-left: 0px !important;
  }
}
@media screen and ( min-width: 601px ) and ( max-width: 800px ) {
  .accordion-title {
    font-size: 1.1rem;
    line-height: 1.25;
  }
  .accordion-title-number {
    font-size: .95rem;
  }
  .accordion-panel .accordion-content p {
    font-size: 1rem;
  }
}
@media screen and ( max-width: 600px ) {
  
  .accordion-title {
    font-size: 1rem;
  }
  .accordion-title-number {
    font-size: .9rem;
  }
  
  .accordion-panel .accordion-content p {
    font-size: .8rem;
  }
  
}