.jo-small-list-posts-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1000px) {
  .jo-small-list-posts-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}