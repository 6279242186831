
.jo-project-content {
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  padding-bottom: 120px;
}
.jo-project-content-header {
  position:relative;
  width: 100%;
  height: auto;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
}
.jo-project-mockups {
  position: relative;
  width: 55%;
}
.jo-project-mockups .jo-mockup-desktop {
  height: auto;
  z-index: 5;
}
.jo-project-mockups .jo-mockup-desktop:hover {
  z-index: 15;
}
.jo-project-mockups .jo-mockup-mobile {
  z-index: 10;
}
.jo-project-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%;
  box-sizing: border-box;
  padding: 20px 40px;
}
.jo-project-info h1 {
  font-size: 3.6rem;
  margin: .5rem auto;
}
.jo-project-info h3 {
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 130%;
  margin: .5rem auto;
}
.jo-project-info a.jo-project-header-project-link {
  margin: 1rem auto;
}
.jo-project-content .jo-post-content-wrapper {
  margin-top: 20px;
  max-width: 900px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.jo-project-technologies {
  font-size: .8rem;
}
.jo-project-client {
  font-size: 1em;
}
.jo-project-client .jo-project-client-text {
  /* font-weight: bold; */
}
.jo-project-info .jo-project-info-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.jo-project-info .jo-project-info-links.jo-project-info-links-double-row {
  flex-direction: column;
  width: 340px;
  margin: .6em auto;
  
}
.jo-project-info-links-double-row div:first-child, .jo-project-info-links-double-row div:nth-child(2){
  flex-grow: 1;
  width: 100%;
}
.jo-project-info .jo-project-info-links div:first-child {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.jo-project-info .jo-project-info-links div:first-child div {
  flex-grow: 1;
}
.jo-project-info-links-double-row div:nth-child(2) .jo-link-button button, .jo-project-info-links-double-row div:first-child .jo-link-button button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.jo-project-info-links-double-row div:nth-child(2) .jo-link-button-wrapper,
.jo-project-info-links-double-row div:nth-child(2) .jo-link-button-wrapper a,
.jo-project-info-links-double-row div:nth-child(2) .jo-link-button-wrapper button,
.jo-project-info .jo-project-info-links div:first-child .jo-link-button-wrapper,
.jo-project-info .jo-project-info-links div:first-child .jo-link-button-wrapper a,
.jo-project-info .jo-project-info-links div:first-child .jo-link-button-wrapper button {
  width: 100%;
  box-sizing: border-box;
}
.jo-project-info-links div.jo-link-button-wrapper {
  padding: 10px 5px;
}
.jo-project-info .jo-link-button-wrapper {
  display: inline-block;
}

/* Screenshots in content */
.wp-caption {
  max-width: 100%;
  box-sizing: border-box;
  padding-right: 20px;
  margin: 2em auto;
}
.jo-project-content p.wp-caption-text {
  font-size: 1em;
  margin: .6em auto;
  text-align: center;
}
img.jo-project-content-screenshot {
  width: auto;
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
  /* box-shadow: 1.4rem 2.4rem 4.8rem 0.6rem rgba(0,0,0,.05); */
  font-size: 0px;
  background: #ddd;
  border-radius: 10px;
  padding: 20px;
  overflow: hidden;
}
/* End Screenshots in content */


@media screen and ( min-width: 1201px ) { 
  .jo-project-content-content {
    padding: 0px 40px 60px 60px;
  }
  .jo-project-content .jo-post-content-wrapper h1 {
    font-size: 4.5rem;
    line-height: 1.05;
  }
  .jo-project-content .jo-post-content-wrapper h3 {
    font-size: 2.2rem;
    line-height: 1.25;
  }
}
@media screen and ( max-width: 1200px ) {
  
}
@media screen and ( min-width: 1001px ) {
  .jo-project-mockups .jo-mockup-desktop {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    left: 10%;
    width: 75%;
    height: auto;
    z-index: 5;
  }
  .jo-project-mockups .jo-mockup-mobile {
    position: absolute;
    width: 30%;
    height: auto;
    top: 70%;
    transform: translateY(-70%);
    right: 2%;
  }
  
  
}
@media screen and ( max-width: 1000px ) {
  .jo-project-content-header {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .jo-project-mockups, .jo-project-info {
    display: block;
    width: 100%;
    margin: 40px auto;
  }
  .jo-project-info {
    padding: 20px 40px;
    max-width: 700px;
  }
  .jo-project-mockups .jo-mockup-desktop {
    position: relative;
    width: 60%;
    left: 15%;
    height: auto;
    margin: 60px 0 0 0;
  }
  .jo-project-mockups .jo-mockup-mobile {
    position: absolute;
    width: 25%;
    bottom: -30px;
    right: 10%;
  }
}
@media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
  .jo-project-content-content {
    padding: 0px 20px 40px 40px;
  }
  .jo-project-content.jo-post-content-wrapper h1 {
    font-size: 3.6rem;
    line-height: 1.05;
  }
  .jo-project-content .jo-post-content-wrapper h2 {
    font-size: 2.5rem;
    line-height: 1.25;
  }
  .jo-project-content .jo-post-content-wrapper h3 {
    font-size: 1.75rem;
    line-height: 1.25;
  }
  .jo-project-content .jo-post-content-wrapper h4 {
    line-height: 1.22222222;    
  }
  .jo-project-content .jo-post-content-wrapper p {
    font-size: 1.4rem;
    line-height: 160%;    
  }
  .jo-project-content p.wp-caption-text {
    font-size: 1em;
  }
}
@media screen and ( min-width: 801px ) and ( max-width: 1000px ) {
  .jo-project-content .jo-post-content-wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media screen and ( max-width: 800px ) {
  img.jo-project-content-screenshot {
    padding: 15px;
  }
}
@media screen and ( min-width: 601px ) and ( max-width: 800px ) {
  .jo-project-info h1 {
    font-size: 2.8rem;
    margin: .5rem auto;
  }
  .jo-project-info h3 {
    font-size: 1.1rem;
    font-weight: normal;
    line-height: 130%;
    margin: .7rem auto;
  }
  .jo-project-content-content {
    padding: 0px 10px 30px 30px;
  }
  .jo-project-content .jo-post-content-wrapper {
    padding: 20px 60px;
  }
  .jo-project-content .jo-post-content-wrapper h1 {
    font-size: 2.7rem;
    /* 2.5x body copy size = 40px */
    line-height: 1.125;
  }
  .jo-project-content .jo-post-content-wrapper h2 {
    font-size: 2.4rem;
    /* 2x body copy size = 32px */
    line-height: 1.25;
  }
  .jo-project-content .jo-post-content-wrapper h3 {
    font-size: 1.8rem;
    /* 1.5x body copy size = 24px */
    line-height: 1.25;
  }
  .jo-project-content .jo-post-content-wrapper h4 {
    line-height: 1.22222222;
    /* (22px / 18px */
  }
  .jo-project-content .jo-post-content-wrapper p {
    font-size: 1.2rem;
    line-height: 155%;
  }
  .jo-project-content p.wp-caption-text {
    font-size: .9em;
  }
}
@media screen and ( max-width: 600px ) {
  
  .jo-project-mockups .jo-mockup-desktop {
    width: 80%;
    left: 7%;
    margin: 45px 0 0 0;
  }
  .jo-project-mockups .jo-mockup-mobile {
    width: 35%;
    bottom: -30px;
    right: 5%;
  }
  .jo-project-mockups, .jo-project-info {
    margin: 25px auto;
  }

  .jo-project-info {
    padding: 20px 20px;
  }
  .jo-project-info h1 {
    font-size: 2.4rem;
    margin: .4rem auto;
  }
  .jo-project-info h3 {
    font-size: 1rem;
    font-weight: normal;
    line-height: 130%;
    margin: .5rem auto;
  }

  .jo-project-content .jo-post-content-wrapper .wp-caption {
    padding-right: 0px;
  }
  .jo-project-content-content {
    padding: 0px 5px 25px 25px;
  }
  .jo-project-content .jo-post-content-wrapper h1 {
    font-size: 2.5rem;
    /* 2.5x body copy size = 40px */
    line-height: 1.125;
  }
  .jo-project-content .jo-post-content-wrapper h2 {
    font-size: 2rem;
    /* 2x body copy size = 32px */
    line-height: 1.25;
  }
  .jo-project-content .jo-post-content-wrapper h3 {
    font-size: 1.5rem;
    /* 1.5x body copy size = 24px */
    line-height: 1.25;
  }
  .jo-project-content .jo-post-content-wrapper h4 {
    line-height: 1.22222222;
    /* (22px / 18px */
  }
  .jo-project-content .jo-post-content-wrapper p {
    font-size: 1rem;
  }
  .jo-project-content p.wp-caption-text {
    font-size: .8em;
  }
}