.jo-transform-box {
  width: 100%;
  height: 100%;
}
.jo-transform-box-inner {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  margin: 0px;
  padding: 0px;
  transition: all .8s cubic-bezier(.42,0,0,1);
  will-change: transform;
  box-shadow: .75rem 1.25rem 2.5rem 0.35rem rgba(0,0,0,.08);
  color: #fff;
  cursor: pointer;
}
.jo-transform-box-inner h1, .jo-transform-box-inner h2, .jo-transform-box-inner h3, .jo-transform-box-inner p, .jo-transform-box-inner a {
  color: #fff;
} 

.jo-transform-box-inner-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: #000;
  transition: all .8s cubic-bezier(.42,0,0,1);
}
.jo-transform-box-inner:hover .jo-transform-box-inner-container {
}
.jo-transform-box-bg-image {
  position: absolute;
  width: 100%;
  height: 100%;
  left:0;
  top:0;
  background-position: center center;
  background-size: cover;
  z-index: 5; 
}
.jo-transform-box-content-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: transparent;
  transition: background-color .8s cubic-bezier(.42,0,0,1);
  z-index: 30;
}
.jo-transform-box-inner:hover .jo-transform-box-content-wrapper {
  background-color: rgba(0,0,0,.7);
}
.jo-transform-box-content {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;  
  transition: all .8s cubic-bezier(.42,0,0,1);
  background-color: none;
  z-index: 40;
}
.jo-transform-box-inner:hover .jo-transform-box-content {
  opacity: 1;
}
.jo-transform-box-content h3.jo-portfolio-item-title, .jo-transform-box-content p.jo-portfolio-item-technologies, .jo-transform-box-content p.jo-portfolio-item-description {
  text-align: center;
}
.jo-transform-box-content h3.jo-portfolio-item-title {
  font-size: 1.2rem;
  font-weight: bold;
}
.jo-transform-box-content p.jo-portfolio-item-technologies {
  font-size: .7rem;
}
.jo-transform-box-content p.jo-portfolio-item-description {
  font-size: 1rem;
  text-transform: none;
}
/* Media Queries */
@media screen and ( min-width: 1201px ) {
  .jo-transform-box-content h3.jo-portfolio-item-title {
    font-size: 1.8rem;
    line-height: 1.25;
    margin-bottom: .5rem;
  }
  .jo-transform-box-content p.jo-portfolio-item-technologies {
    font-size: .7rem;
    margin-bottom: .7rem;
  }
  .jo-transform-box-content p.jo-portfolio-item-description {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1.2rem;
  }
}
@media screen and ( min-width: 1001px ) and ( max-width: 1200px ) {
  .jo-transform-box-content h3.jo-portfolio-item-title {
    font-size: 1.5rem;
    line-height: 1.25;
    margin-bottom: .5rem;
  }
  .jo-transform-box-content p.jo-portfolio-item-technologies {
    font-size: .6rem;
    margin-bottom: .5rem;
  }
  .jo-transform-box-content p.jo-portfolio-item-description {
    font-size: 1rem;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
}
@media screen and ( max-width: 1000px ) {
  .jo-transform-box-content h3.jo-portfolio-item-title {
    font-size: 1.3rem;
    line-height: 1.25;
    margin-bottom: .3rem;
  }
  .jo-transform-box-content p.jo-portfolio-item-technologies {
    font-size: .6rem;
    margin-bottom: .5rem;
  }
  .jo-transform-box-content p.jo-portfolio-item-description {
    font-size: .9rem;
    line-height: 1.2;
    margin-bottom: .7rem;
  }
  .jo-transform-box-content .jo-link-button-wrapper {
    margin-top: .5rem;
  }
}
@media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
  
}
@media screen and (min-width: 601px) and (max-width: 1200px) {
 
}
@media screen and ( min-width: 801px ) {

}
@media screen and ( max-width: 800px ) {
  
}
@media screen and ( min-width: 601px ) and ( max-width: 800px ) {
  
}
@media screen and ( max-width: 600px ) {
  
}