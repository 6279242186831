.latest-posts .jo-content {
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.jo-blog-loading {
  font-size: 5em;
  -webkit-animation: white-rainbow 6s infinite;
  -ms-animation: white-rainbow 6s infinite;
  -o-animation: white-rainbow 6s infinite;
  animation: white-rainbow 6s infinite;
}
.jo-more-posts-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3em 0 6em;
}
.jo-more-posts-wrapper .jo-loading-shape {
  
}
@media screen and (max-width: 600px) {
  .jo-blog-loading {
    font-size: 2.5em;
  }
}