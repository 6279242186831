.what-we-do-heading {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  margin-bottom: 1em;
  margin-left: 24px;
  width: 100%;
  margin-bottom: 1em;
  margin-left: 24px;
}
.what-we-do-heading h3 {
  width: 80%; 
  text-align: left;
  font-weight:500;
  font-size: 1.8em;
  line-height: 110%;
}
#home-section-two .home-section-content {
  padding-top: 10%;
}
.what-we-do {
  position:relative;
  box-sizing: border-box;
  background: #fff;
}
.home-section-two-bg {
  position: absolute;
  width: 60%;
  background-size: cover;
  background-position: right center;
  height: 70%;
  height: 70vh;
  right: 0px;
  top: 30%;
  top: 30vh;
  background: #ccc;
}

@media screen and (min-width: 1601px) {
  .what-we-do {
    width: 60%;
    max-width: 1200px;
    padding:  102px 100px;
    box-shadow: 1.5rem 2.5rem 5rem 0.7rem rgba(0,0,0,.08);
  }
  .what-we-do-heading {
    margin-bottom: 1.8em;
  }
  .what-we-do-heading h3 {
    font-size: 2.4em;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1600px) {
  .what-we-do {
    max-width: 700px;
    padding:  82px 80px;
    box-shadow: 1.5rem 2.5rem 5rem 0.7rem rgba(0,0,0,.08);
  }
  .what-we-do-heading {
    margin-bottom: 1em;
  }
  .what-we-do-heading h3 {
    font-size: 2em;
  }
}
@media screen and ( min-width: 1201px ) {
  #home-section-two .home-section-content {
    padding-top: 7%;
  }
}
@media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
  .what-we-do {
    max-width: 660px;
    padding:  66px 60px;
    box-shadow: .75rem 1.25rem 2.5rem 0.35rem rgba(0,0,0,.08);
  }
  .what-we-do-heading {
    margin-bottom: 1em;
  }
  .what-we-do-heading h3 {
    font-size: 1.8em;
  }
}
@media screen and (min-width: 801px) {
  
}
@media screen and (max-width: 800px ) {
  .what-we-do {
    padding: 0px;
  }
  .home-section-two-bg {
    display: none;
  }
  
}
@media screen and (min-width: 601px) and (max-width: 800px) {
  
  .what-we-do-heading {
    margin-bottom: 1em;
    margin-left: 0;
  }
  .what-we-do-heading h3 {
    max-width: 60%;
    font-size: 1.4em;
  }
  
}
@media screen and (max-width: 600px ) {
  .what-we-do-heading {
    margin-bottom: 1em;
    margin-left: 0;
  }
  .what-we-do-heading h3 {
    font-size: 1.4em;
  }
  
}
@media screen and (max-width: 462px) {
  
}