.jo-not-found {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.jo-not-found svg {
  width: 30%;
  height: auto;
}
.jo-not-found-text {
  width: 70%;
  display: flex;
  flex-direction:column;
  align-items: flex-start;
  justify-content: center;
}
.jo-not-found-text h1 {
  text-transform: uppercase;
  margin-bottom: 10px;
}
.jo-not-found-text h3 {
  -webkit-animation: white-rainbow 6s infinite;
  -ms-animation: white-rainbow 6s infinite;
  -o-animation: white-rainbow 6s infinite;
  animation: white-rainbow 6s infinite;
}
.jo-not-found-text a {
  text-decoration: none;
}
@media screen and ( min-width: 1201px ) { 
  .jo-not-found-text h1 {
    font-size: 3.8rem;
  }
  .jo-not-found-text h3 {
    font-size: 2.35rem;
  }
}
@media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
  .jo-not-found-text h1{
   font-size: 3.2rem;
  }
  .jo-not-found-text h3 {
    font-size: 1.95rem;
  }
}
@media screen and ( min-width: 601px ) and ( max-width: 800px ) {
  .jo-not-found-text h1 {
    font-size: 2.7rem;
  }
  .jo-not-found-text h3 {
    font-size: 1.6rem;
  }
}
@media screen and ( max-width: 600px ) {
  .jo-not-found-text h1 {
    font-size: 1.8rem;
  }
  .jo-not-found-text h3 {
    font-size: 1.1rem;
  }
}