.about-page {
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding-bottom: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.about-page h1, .about-page p {
  text-align: left;
}
.about-page .jo-work-header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0px;
}
.about-page .jo-work-header-content h1.jo-work-header-title {
  text-align: center;
}
@media screen and (min-width: 800px) {
  .about-page section {
    padding: 40px 0px;
  }
  
}