#jo-home-boids {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  height: 100vh;
  width: 100%;
  width: 100vw;
  max-height: 100%;
  max-height: 100vh;
  max-width: 100%;
  max-width: 100vw;
  overflow: hidden;
}
