.jo-logo-wrapper {
  position: relative;
  padding-bottom: 85%;
  left: 8%;
  width: 85%;
  height:0;
  transform: rotate(15deg);
  float: left;
}
.jo-logo {
  position: absolute;
  width: 100%;
  height: 100%;
}
.jo-logo > svg {
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
}