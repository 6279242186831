.jo-site-mockup{
  width: auto;
  max-width: 100%;
  height: auto;
  box-shadow: 1.4rem 2.4rem 4.8rem 0.6rem rgba(0,0,0,.12);
  font-size: 0px;
  border-radius: 5px;
  overflow: hidden;
}
.jo-site-mockup.jo-mockup-desktop .jo-site-mockup-container {
  padding-bottom: 106.67%;
}
.jo-site-mockup.jo-mockup-mobile .jo-site-mockup-container {
  padding-bottom: 156.09%;
}
.jo-site-mockup.jo-mockup-mobile {
  background: #ddd;
  border-radius: 10px;
  padding: 20px 15px;
  box-shadow: -1.4rem 2.4rem 4.8rem 0.6rem rgba(0,0,0,.12);
}
.jo-site-mockup-header {
  width: 100%;
  height: auto;
}
.jo-site-mockup-container {
  width: 100%;
  height: 0px;
  position: relative;
  background: #999;
  overflow:hidden;
}
.jo-mockup-mobile .jo-site-mockup-container {
  background: #999;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.jo-site-mockup-container img {
  width: 100%;
  height: auto;
  left:0;
  top:0;
  position: absolute;
}
.jo-site-mockup-container svg {
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.jo-mockup-desktop .jo-site-mockup-container svg {
  width: 50%;
}
.jo-mockup-mobile .jo-site-mockup-container svg {
  width: 70%;
}
.jo-site-mockup-container svg path {
  fill: #aaa;
}
@media screen and (max-width: 1000px) {
  .jo-site-mockup.jo-mockup-mobile {
    border-radius: 8px;
    padding: 15px 12px;
    box-shadow: -.5rem .5rem 3.6rem 0.6rem rgba(0,0,0,.12);
  }
}
@media screen and (max-width: 600px) {
  .jo-site-mockup.jo-mockup-mobile {
    border-radius: 7px;
    padding: 13px 10px;
    box-shadow: -.5rem .5rem 3.6rem 0.3rem rgba(0,0,0,.12);
  }
}