/* Nav */
#main-nav {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  left: 0%;
  z-index: 10;
  height: 100%;
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  padding: 80px 20px 60px;
  opacity: 0;
  background: #191919;
  overflow: hidden;
  color: #fff;
  -webkit-transition: opacity .3s ease-in-out; /* Safari */
  transition: opacity .3s ease-in-out;
  z-index: 0;
}
#main-nav.initialized {
  opacity: 1;
  animation-name: fade-out-right;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  -webkit-animation-duration: 0.4s;
  -webkit-animation-name: fade-out-right;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}
#main-nav.front {
  z-index: 100;
}
#main-nav.toggled {
  animation-duration: 0.4s;
  animation-name: fade-in-left-full;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  -webkit-animation-duration: 0.4s;
  -webkit-animation-name: fade-in-left-full;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 1;
}
#main-nav ul {
  opacity: 1;
  list-style-type: none;
  text-align: center;
  margin: auto;
  padding: 0; 
  z-index: 100;
}
@keyframes fade-out-right {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 1;
  }
}
@keyframes fade-in-left-full {
  from {
    opacity: 1;
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.main-nav ul li {
  font-size: 6em;
  line-height: 105%;
}
.main-nav ul li a {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: color .5s; /* Safari */
  transition: color .5s;
  font-family: 'Geomanist', 'OpenSans', 'Helvetica', sans-serif;
  font-weight: 600;
}
.main-nav ul li a:hover, .main-nav ul li a:active, .main-nav ul li a:focus {
  -webkit-animation: white-rainbow 5s infinite;
  -ms-animation: white-rainbow 5s infinite;
  -o-animation: white-rainbow 5s infinite;
  animation: white-rainbow 5s infinite;
}
.jo-nav-shape {
  width: 40%;
  max-width: 400px;
  height: auto;
  position: absolute; 
}
.jo-nav-shape path, .jo-nav-shape polygon, .jo-nav-shape rect {
  fill: #222;
}
.jo-nav-shape-2 {
  bottom: 3%;
  right: 3%;
}
.jo-nav-shape-5 {
  left: 4%;
  bottom: 20%;
}
.jo-nav-shape.jo-nav-shape-5 {
  width: 30%;
  max-width: 300px;
}

@media screen and ( min-width: 601px ) and ( max-width: 800px ) {
  .main-nav ul li {
    font-size: 5em;
  }
  #main-nav ul {
    margin: auto 0 auto 2em;
  }
  .jo-nav-shape-5 {
    left: 50%;
    top: 10%;
  }
}
@media screen and ( max-width: 600px ) {
  .main-nav ul li {
    font-size: 3.6em;
  }
  #main-nav ul {
    margin: auto 0 auto 1.2em;
  }
  .jo-nav-shape-5 {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  #main-nav {
    justify-content: flex-start;
  }
  #main-nav ul {
    text-align: left;
  }
}