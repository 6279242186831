.jo-map-wrapper {
  position: relative;
  width: 45%;
  min-width: 200px;
  max-width: 600px;
  height: 100%;
  min-height: 100%;
}
#map {
  width: 100%;
  height: 100%;
}
