.home-portfolio-wrapper {
  position:relative;
  width: 100%;
}
.home-portfolio-base-desktop > svg {
 grid-area: 1 / 1 / 10 / 6;
}
.home-portfolio-base-mobile {
  min-height: 400px;
  height: auto;
  width: 100%;
}
.home-portfolio-base-mobile h2 {
  font-size: 2.8rem;
  margin: 1rem auto 2.4rem;
}
.home-portfolio-base-mobile .jo-link-button-wrapper {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0px;
}
.home-portfolio-base-mobile .jo-link-button-wrapper a {
  display: inline;
  margin: auto;
}

@media screen and ( min-width: 1201px ) { 
  
}
@media screen and ( max-width: 1200px ) {
  
}
@media screen and ( min-width: 1001px ) {
  
}
@media screen and ( min-width: 801px ) {
  .home-portfolio-wrapper {
    margin: 150px auto;
  }
  .home-portfolio-base-mobile {
    display: none;
  }
  .home-portfolio-base-desktop {
    position: relative;
    display: grid;
    grid-template-columns: 31.5% 11.5% 14% 11.5% 31.5%;
    grid-template-rows: 9.29% 8.21% 23.21% 8.21% 2.14% 8.21% 23.21% 8.21% 9.29%;
    width: 80%;
    margin: auto;
    height: auto;
    max-width: 1400px;
    overflow: visible;
  }
  .home-portfolio-block {
    position: relative;
    height: 100%;
    /* background: #ccc; */
  }
  .home-portfolio-block-1 {
    grid-column:1;
    grid-row: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .home-portfolio-block-2 {
    grid-column: 3/6;
    grid-row: 1/4;
  }
  .home-portfolio-block-3 {
    grid-column: 1;
    grid-row: 3/6;
  }
  .home-portfolio-block-4 {
    grid-column: 5;
    grid-row: 5/8;
  }
  .home-portfolio-block-5 {
    grid-column: 1/4;
    grid-row: 7/10; 
  }
  .home-portfolio-block-6 {
    grid-column: 5;
    grid-row: 9;
    display: flex;
    justify-content:center;
    align-items: flex-start;
  }
}
@media screen and ( min-width: 801px ) and ( max-width: 1000px ) {
  .home-portfolio-base-desktop {
    grid-template-columns: 31.5% 11.5% 14% 11.5% 31.5%;
    grid-template-rows: 9.29% 8.21% 23.21% 8.21% 2.14% 8.21% 23.21% 8.21% 9.29%;
    width: 100%;
  }
}
@media screen and ( max-width: 800px ) {
  .home-portfolio-wrapper {
    margin: 80px auto 120px;
  }
  .home-portfolio-wrapper .home-section-content, .home-portfolio-base-desktop {
    display: none;
  }
  .home-portfolio-base-mobile {
    display: block;
    width: 100%;
  }
  
}
@media screen and ( min-width: 601px ) and ( max-width: 800px ) {
 
}
@media screen and ( max-width: 600px ) {
  .jo-transform-box {

  }
}