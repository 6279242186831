.jo-work-header {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  height: 40vh;
  max-height: 800px;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
}
.jo-work-header-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
} 
.jo-work-header-content {
  width: auto;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}
.jo-work-header-content h1.jo-work-header-title {
  color: #fff;
  font-weight: bold;
  z-index: 10;
  text-align: left;
}
.jo-work-header-content h3 {
  color: #fff;
  font-weight: bold;
  z-index: 10;
  text-aling: left;
}
@media screen and ( min-width: 1201px ) { 
  .jo-work-header-content {
    padding: 0px 40px 60px 60px;
  }
  .jo-work-header h1 {
    font-size: 4.5rem;
    line-height: 1.05;
  }
  .jo-work-header h3 {
    font-size: 2.2rem;
    line-height: 1.25;
  }
}
@media screen and ( max-width: 1200px ) {
  
}
@media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
  .jo-work-header-content {
    padding: 0px 20px 40px 40px;
  }
  .jo-work-header h1 {
    font-size: 3.6rem;
    line-height: 1.05;
  }
  .jo-work-header h2 {
    font-size: 2.5rem;
    line-height: 1.25;
  }
  .jo-work-header h3 {
    font-size: 1.75rem;
    line-height: 1.25;
  }
  .jo-work-header h4 {
    line-height: 1.22222222;    
  }
  .jo-work-header p {
    font-size: 1.4rem;
    line-height: 160%;    
  }
}
@media screen and ( min-width: 800px ) {
  
}
@media screen and ( min-width: 601px ) and ( max-width: 800px ) {
  .jo-work-header {
    height: 40vh;
  }
  .jo-work-header-content {
    padding: 0px 10px 30px 30px;
  }
  .jo-work-header h1 {
    font-size: 2.7rem;
    /* 2.5x body copy size = 40px */
    line-height: 1.125;
  }
  .jo-work-header h2 {
    font-size: 2.4rem;
    /* 2x body copy size = 32px */
    line-height: 1.25;
  }
  .jo-work-header h3 {
    font-size: 1.8rem;
    /* 1.5x body copy size = 24px */
    line-height: 1.25;
  }
  .jo-work-header h4 {
    line-height: 1.22222222;
    /* (22px / 18px */
  }
  .jo-work-header p {
    font-size: 1.2rem;
    line-height: 155%;
  }
}
@media screen and ( max-width: 600px ) {
  .jo-work-header {
    height: 40vh;
  }
  .jo-work-header-content {
    padding: 0px 5px 25px 25px;
  }
  .jo-work-header h1 {
    font-size: 2.5rem;
    /* 2.5x body copy size = 40px */
    line-height: 1.125;
  }
  .jo-work-header h2 {
    font-size: 2rem;
    /* 2x body copy size = 32px */
    line-height: 1.25;
  }
  .jo-work-header h3 {
    font-size: 1.5rem;
    /* 1.5x body copy size = 24px */
    line-height: 1.25;
  }
  .jo-work-header h4 {
    line-height: 1.22222222;
    /* (22px / 18px */
  }
  .jo-work-header p {
    font-size: 1rem;
  }
}