.contact-page {
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding: 80px 0px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-page .jo-post-content-wrapper {
  text-align: center;
  padding: 40px 0;
}